import * as actionTypes from "../actions/types";

const INITIAL_STATE = {
  showLoader: false,
};

const loaderReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADER:
      return {
        ...state,
        showLoader: true,
      };
    case actionTypes.HIDE_LOADER:
      return {
        ...state,
        showLoader: false,
      };
    default:
      return state;
  }
};

export default loaderReducers;
