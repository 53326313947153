import * as actionTypes from "../actions/types";

const INITIAL_STATE = {
  testimonials: [],
  ourSignature: [],
  storeDetails: {
    instagram: "",
    primary_phone_number: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    fax: "",
    privacy_policy: "",
    store_timings: "",
    facebook: "",
    twitter: "",
    google: "",
    you_tube: "",
    yelp: "",
    ingredients: "",
    food_items: "",
    daily_food_lovers: "",
    year_of_experience: "",
    tax_on_food: 0,
    store_status:0,
  },
};

const siteSettingReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SITE_SETTINGS:
      return {
        ...state,
        testimonials: action.payload.testimonials,
        ourSignature: action.payload.ourSignature,
        storeTiming: action.payload.storeTiming,
        storeDetails: {
          instagram: action.payload.storeDetails.instagram,
          facebook: action.payload.storeDetails.facebook,
          twitter: action.payload.storeDetails.twitter,
          google: action.payload.storeDetails.google,
          you_tube: action.payload.storeDetails.you_tube,
          store_timings: action.payload.storeDetails.store_timings,
          primary_phone_number:
            action.payload.storeDetails.primary_phone_number,
          address_1: action.payload.storeDetails.address_1,
          address_2: action.payload.storeDetails.address_2,
          city: action.payload.storeDetails.city,
          state: action.payload.storeDetails.state,
          zip: action.payload.storeDetails.zip,
          email: action.payload.storeDetails.email,
          privacy_policy: action.payload.storeDetails.privacy_policy,
          fax: action.payload.storeDetails.fax,
          yelp: action.payload.storeDetails.yelp,
          ingredients: action.payload.storeDetails.ingredients,
          food_items: action.payload.storeDetails.food_items,
          daily_food_lovers: action.payload.storeDetails.daily_food_lovers,
          year_of_experience: action.payload.storeDetails.year_of_experience,
          tax_on_food: action.payload.storeDetails.tax_on_food,
          store_status: action.payload.storeDetails.store_status,
        },
      };
    default:
      return state;
  }
};

export default siteSettingReducers;
