import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ReCAPTCHA from "react-google-recaptcha";
import { register } from "../../actions";

class RegisterForm extends React.Component {
  captcha = {};

  state = {
    isRedirect: false,
    redirectURL: "/",
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <div className="ui error message text-danger">{error}</div>;
    }
  }

  renderInput = ({ input, label, inputId, placeholder, type, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label htmlFor={inputId}>{label}</label>
        <input
          type={type}
          {...input}
          title={label}
          id={inputId}
          autoComplete="off"
          placeholder={placeholder}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  renderRecaptchaField = (field) => {
    const {
      meta: { touched, error },
    } = field;
    return (
      <div style={{ marginTop: "20px" }}>
        <ReCAPTCHA
          sitekey="6LdhMqAdAAAAAH43xQyKICMAYZ3RH-hrg_giFgCE"
          onChange={field.input.onChange}
          ref={(el) => {
            this.captcha = el;
          }}
        />
        <div>
          <p className="text-danger">{touched ? error : ""}</p>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.register(formValues);

    if (this.props.auth.show_verification) {
      this.setState({ isRedirect: true, redirectURL: "/email-verification" });
      this.captcha.reset();
    }
  };

  render() {
    return (
      <form
        style={{ marginBottom: "20px" }}
        className="sidebar__form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        {/* this.props.auth.error ? (
          <p className="red">{this.props.auth.error}</p>
        ) : null */}
        <Field
          name="phone"
          type="number"
          placeholder="Phone Number"
          component={this.renderInput}
        />
        <Field
          name="name"
          type="text"
          placeholder="Name"
          component={this.renderInput}
        />
        <Field
          name="email"
          type="email"
          placeholder="Email"
          component={this.renderInput}
        />
        <Field
          name="cemail"
          type="email"
          placeholder="Confirm Email"
          component={this.renderInput}
        />
        <Field
          name="password"
          type="password"
          placeholder="Password"
          component={this.renderInput}
        />
        <Field
          name="cpassword"
          type="password"
          placeholder="Confirm Password"
          component={this.renderInput}
        />
        <Field
          name="address"
          type="text"
          placeholder="Address"
          component={this.renderInput}
        />
        <Field
          name="city"
          type="text"
          placeholder="City"
          component={this.renderInput}
        />
        <Field
          name="zipcode"
          type="text"
          placeholder="Zipcode"
          component={this.renderInput}
        />

        <Field name="recaptcha" component={this.renderRecaptchaField} />
        <span>
          By creating an account, I accept the Terms & Conditions & Privacy
          Policy
        </span>
        <button
          type="submit"
          className="orange__btn text-uppercase"
          style={{ marginTop: "20px" }}
        >
          continue
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  const { recaptcha } = formValues;

  if (!recaptcha) {
    errors.recaptcha = "reCAPTCHA required.";
  }

  if (!formValues.phone) {
    errors.phone = "Enter Mobile Number";
  }

  /* if (!formValues.address) {
    errors.address = "Enter Address";
  }

  if (!formValues.city) {
    errors.city = "Enter City";
  }

  if (!formValues.zipcode) {
    errors.zipcode = "Enter Zipcode";
  } */

  if (!formValues.password) {
    errors.password = "Enter Password";
  } else if (formValues.password.length < 5) {
    errors.password = "Must be 5 characters or more";
  }

  if (!formValues.cpassword) {
    errors.cpassword = "Enter Confirm Password";
  } else if (formValues.cpassword.length < 5) {
    errors.cpassword = "Must be 5 characters or more";
  } else if (formValues.password !== formValues.cpassword) {
    errors.cpassword = "Password and confirm password must be same";
  }

  if (!formValues.email) {
    errors.email = "Enter Email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.email = "Invalid email address";
  }

  if (!formValues.cemail) {
    errors.cemail = "Enter Confirm Email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.cemail = "Invalid confirm email address";
  }

  if (formValues.email !== formValues.cemail) {
    errors.cemail = "Email and confirm email must be same";
  }

  if (!formValues.name) {
    errors.name = "Enter Name";
  }
  return errors;
};

const formWrapper = reduxForm({
  validate,
  form: "registerForm",
})(RegisterForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { register })(formWrapper);
