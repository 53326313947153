import React from "react";
import { connect } from "react-redux";
import "./Loader.css";

const Loader = (props) => {
  return props.loader.showLoader ? (
    <div className="lds-ripple">
      <div className="loader">
        <div></div>
        <div></div>
      </div>
    </div>
  ) : null;
};

const stateMapToProps = (state) => {
  return {
    loader: state.showLoader,
  };
};

export default connect(stateMapToProps)(Loader);
