import * as actionTypes from "../actions/types";
const INITIAL_STATE = {
  sidebarOpen: false,
};

const sidebarReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SIDEBAR_OPEN:
      window.jQuery(".sidebar").addClass("active");
      window.jQuery("body").css("overflow", "hidden");
      window.jQuery("body").append('<div class="sidebar-dropshadow"></div>');
      return { ...state, sidebarOpen: true };
    case actionTypes.SIDEBAR_CLOSE:
      window.jQuery(".sidebar").removeClass("active");
      window.jQuery("body").css("overflow", "auto");
      window.jQuery(".sidebar-dropshadow").remove();
      return { ...state, sidebarOpen: false };
    default:
      return state;
  }
};

export default sidebarReducers;
