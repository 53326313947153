import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const PrivacyPolicy = (props) => {
  return (
    <>
      <header className="header-2 sticky-top">
        <div className="container">
          <nav className="d-flex align-items-center justify-content-between">
            <div>
              <Link to="/">
                <img src="images/logo.png" alt="logo" height="80px" />
              </Link>
              <span className="fw700 fs14 ml-4">PRIVACY POLICY</span>
            </div>
            {/* <div className="header-2__navigation">
              <Link to="/contact-us" className="fs16">
                <i className="fa fa-question-circle" aria-hidden="true"></i>
                Help
              </Link>
            </div> */}
          </nav>
        </div>
      </header>
      <section className="section privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="privacy-policy__content"
                dangerouslySetInnerHTML={{
                  __html: props.settings.storeDetails.privacy_policy,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};
export default connect(mapStateToProps)(PrivacyPolicy);
