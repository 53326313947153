import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { forgotPassword } from "../../actions";

class ForgotPassword extends React.Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div className="ui error message text-danger">{error}</div>;
    }
  }

  renderInput = ({ input, label, inputId, placeholder, type, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label htmlFor={inputId}>{label}</label>
        <input
          type={type}
          {...input}
          title={label}
          id={inputId}
          autoComplete="off"
          placeholder={placeholder}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.forgotPassword(formValues);
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        {/* this.props.auth.error ? (
          <p className="red error">{this.props.auth.error}</p>
        ) : null */}
        <Field
          name="email"
          type="text"
          placeholder="Email"
          component={this.renderInput}
        />
        <button type="submit" className="orange__btn text-uppercase">
          Submit
        </button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) {
    errors.email = "Enter Email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.email = "Invalid email address";
  }
  return errors;
};

const formWrapper = reduxForm({
  validate,
  form: "forgotPassword",
})(ForgotPassword);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { forgotPassword })(formWrapper);
