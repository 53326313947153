import * as actionTypes from "../actions/types";

const INITIAL_STATE = {
  name: null,
  phone: null,
  address: null,
  city: null,
  zipcode: null,
};

const profileReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE:
      return {
        ...state,
        name: action.payload.name,
        phone: action.payload.phone,
        address: action.payload.address,
        city: action.payload.city,
        zipcode: action.payload.zipcode,
      };
    default:
      return state;
  }
};

export default profileReducers;
