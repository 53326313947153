import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Home extends React.Component {
  componentDidMount() {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 500);

    const hoverLayer = window.jQuery(".home__banner");
    const heroImgOne = window.jQuery(".home__banner-burger .bs1");
    const heroImgTwo = window.jQuery(".home__banner-burger .bs2");
    const heroImgThree = window.jQuery(".home__banner-burger .bs3");
    const heroImgFour = window.jQuery(".home__banner-burger .bs4");
    const heroImgFive = window.jQuery(".home__banner-burger .bs5");

    hoverLayer.mousemove(function (e) {
      const valueX = (e.pageX * -1) / 100;
      const valueY = (e.pageY * -1) / 120;
      heroImgOne.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    hoverLayer.mousemove(function (e) {
      const valueX = (e.pageX * -1) / 60;
      const valueY = (e.pageY * -1) / 80;
      heroImgTwo.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      const valueX = (e.pageX * -1) / 40;
      const valueY = (e.pageY * -1) / 60;
      heroImgThree.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      const valueX = (e.pageX * -1) / 80;
      const valueY = (e.pageY * -1) / 100;
      heroImgFour.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });
    hoverLayer.mousemove(function (e) {
      const valueX = (e.pageX * -1) / 80;
      const valueY = (e.pageY * -1) / 100;
      heroImgFive.css({
        transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
      });
    });

    window.jQuery(".owl-testi").owlCarousel({
      loop: false,
      margin: 0,
      dots: false,
      nav: true,
      responsiveClass: true,
      items: 1,
      navText: [
        "<img src='./images/arrow-previous.png' alt='arrow-previous'>",
        "<img src='./images/arrow-next.png' alt='arrow-next'>",
      ],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.settings.testimonials !== prevProps.settings.testimonials) {
      window.jQuery(".owl-testi").owlCarousel({
        loop: false,
        margin: 0,
        dots: false,
        nav: true,
        responsiveClass: true,
        items: 1,
        navText: [
          "<img src='./images/arrow-previous.png' alt='arrow-previous'>",
          "<img src='./images/arrow-next.png' alt='arrow-next'>",
        ],
      });
    }
  }

  renderTestimonials() {
    return this.props.settings.testimonials.map((testimonial, index) => {
      return (
        <div className="item text-center" key={index}>
          <div className="content__item">
            <div className="comment brown-txt ffr mb-5">
              {testimonial.description}
            </div>
            <h4 className="orange-txt ffs">{testimonial.name}</h4>
            <div className="designation grey-txt mt-3">
              {testimonial.designation}
            </div>
          </div>
        </div>
      );
    });
  }

  renderSignatureItems() {
    return this.props.settings.ourSignature.map((signature, index) => {
      return (
        <div
          className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
          key={index}
        >
          <div className="delicious__item">
            <div className="image mb-4">
              <img src={signature.image} className="img-fluid" alt="Meal 1" />
            </div>
            <h6 className="text-uppercase brown-txt ffr fw600 mb-3">
              {signature.name}
            </h6>
            <div className="meal__detail">
              <ul className="ratings">
                <li className="rating grey-txt">4.9</li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
                <li>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </li>
              </ul>
              <h5 className="brown-txt price mb-0">${signature.price}</h5>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div
          className="home__banner position-relative"
          style={{ backgroundImage: "url('images/banner-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center">
                <div className="content__item mt-5">
                  <h1>ARE YOU HUNGRY?</h1>
                  <div className="desc my-4 pb-4">
                    As well known and we are very busy all days advice you.
                    advice you to call us of before arriving.
                  </div>
                  <Link
                    to="/order-now"
                    className="green__btn"
                    title="Order Now"
                  >
                    Order Now
                  </Link>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-end">
                <div className="home__banner-burger text-right">
                  <img src="images/item.png" alt="item" className="img-fluid" />
                  <span className="bs1">
                    <img src="images/layer1.png" alt="" className="img-fluid" />
                  </span>
                  <span className="bs2">
                    <img src="images/layer2.png" alt="" className="img-fluid" />
                  </span>
                  <span className="bs3">
                    <img src="images/layer3.png" alt="" className="img-fluid" />
                  </span>
                  <span className="bs4">
                    <img src="images/layer4.png" alt="" className="img-fluid" />
                  </span>
                  <span className="bs5">
                    <img src="images/layer5.png" alt="" className="img-fluid" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="section services orange-bg"
          style={{ backgroundImage: "url('images/service-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="service__item text-center">
                  <div className="icon mb-5">
                    <img
                      src="images/service-1.png"
                      className="img-fluid"
                      alt="Service 1"
                    />
                  </div>
                  <h5 className="white-txt text-uppercase">Order Your Food</h5>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="service__item text-center">
                  <div className="icon mb-5">
                    <img
                      src="images/service-2.png"
                      className="img-fluid"
                      alt="Service 2"
                    />
                  </div>
                  <h5 className="white-txt text-uppercase">
                    dine in OR PICK UP
                  </h5>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="service__item text-center">
                  <div className="icon mb-5">
                    <img
                      src="images/service-3.png"
                      className="img-fluid"
                      alt="Service 3"
                    />
                  </div>
                  <h5 className="white-txt text-uppercase">
                    DELICIOUS RECEIPE
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section delicious__meal"
          style={{ backgroundImage: "url('images/delicious-meal-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="section__top text-center pb-4">
                  <h5 className="orange-txt ffs mb-4">Our Signature</h5>
                  <h3 className="brown-txt text-uppercase lett1">
                    Delicious Meals
                  </h3>
                </div>
              </div>
            </div>
            <div className="delicious__item__main mt-5">
              <div className="row">{this.renderSignatureItems()}</div>
              <div className="row">
                <div className="col-12 text-center">
                  <Link to="/our-menu" className="green__btn" title="View All">
                    See All Menu
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section faves"
          style={{ backgroundImage: "url('images/faves-bg.png')" }}
        >
          <div className="container">
            <div className="delicious__item__main mt-5">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="content__item text-center">
                    <h5 className="white-txt ffs">Tasty how The new</h5>
                    <h2 className="white-txt text-uppercase ffl mt-3 mb-4">
                      Meet Your New <br />
                      Lunchtime Faves
                    </h2>
                    <Link to="/order-now" className="orange__btn mt-4">
                      Order Online
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-150 who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="image">
                  <img
                    src="images/who-we-are-img.png"
                    className="img-fluid"
                    alt="Who we are"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="content__item">
                  <h5 className="orange-txt ffs">Who We Are?</h5>
                  <h3 className="brown-txt lett1 ffl my-4">
                    BEST FOOD IDEAS & <br />
                    TRADITIONS FROM THE <br />
                    MIAMI
                  </h3>
                  <div className="desc pb-3">
                    Pita Fresh is not just one of the most popular places for
                    delivery in Miami, it's one of the 3 Mediterranean delivery
                    spots with the most orders. Wish you could ask someone
                    what's best here? Users love the chicken kebab, which is one
                    of the most popular items on the menu at this evening
                  </div>
                  <div className="who__we__item__main mt-4">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="who__we__item">
                          <div className="icn light-orange-bg">
                            <img
                              src="images/w-icn-1.png"
                              className="img-fluid"
                              alt="W Icon"
                            />
                          </div>
                          <h6 className="brown-txt ffr fw600">Fresh Food</h6>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="who__we__item">
                          <div className="icn light-orange-bg">
                            <img
                              src="images/w-icn-2.png"
                              className="img-fluid"
                              alt="W icon 2"
                            />
                          </div>
                          <h6 className="brown-txt ffr fw600">
                            Quality Maintain
                          </h6>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="who__we__item">
                          <div className="icn light-orange-bg">
                            <img
                              src="images/w-icn-3.png"
                              className="img-fluid"
                              alt="W Icon 3"
                            />
                          </div>
                          <h6 className="brown-txt ffr fw600">
                            100% Halal Meat
                          </h6>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="who__we__item">
                          <div className="icn light-orange-bg">
                            <img
                              src="images/w-icn-4.png"
                              className="img-fluid"
                              alt="W Icon 4"
                            />
                          </div>
                          <h6 className="brown-txt ffr fw600">
                            Variety of Choices
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section
          className="section testimonial"
          style={{ backgroundImage: "url('images/testimonial-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="owl-carousel owl-testi owl-theme">
                  <div className="item text-center">
                    <div className="content__item">
                      <div className="comment brown-txt ffr mb-5">
                        “We have no regrets! I don't know what else to say. It
                        really <br />
                        saves me time and effort. Food is exactly what our
                        business <br />
                        has been lacking”
                      </div>
                      <h4 className="orange-txt ffs">Davis J. Rulia</h4>
                      <div className="designation grey-txt mt-3">
                        Food Bloger
                      </div>
                    </div>
                  </div>
                  <div className="item text-center">
                    <div className="content__item">
                      <div className="comment brown-txt ffr mb-5">
                        “We have no regrets! I don't know what else to say. It
                        really <br />
                        saves me time and effort. Food is exactly what our
                        business <br />
                        has been lacking”
                      </div>
                      <h4 className="orange-txt ffs">Davis J. Rulia</h4>
                      <div className="designation grey-txt mt-3">
                        Food Bloger
                      </div>
                    </div>
                  </div>
                  <div className="item text-center">
                    <div className="content__item">
                      <div className="comment brown-txt ffr mb-5">
                        We have no regrets! I don't know what else to say. It
                        really <br />
                        saves me time and effort. Food is exactly what our
                        business <br />
                        has been lacking
                      </div>
                      <h4 className="orange-txt ffs">Davis J. Rulia</h4>
                      <div className="designation grey-txt mt-3">
                        Food Bloger
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(Home);
