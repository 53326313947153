import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";
import SidebarLogin from "./SidebarLogin";
import Home from "./Home";
import EmailVerification from "./EmailVerification";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import OrderList from "./orders/OrderList";
import OurMenu from "./OurMenu";
import CheckOut from "./CheckOut";
import NotFound from "./NotFound";
import OrderNow from "./OrderNow";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import PrivacyPolicy from "./PrivacyPolicy";
import Loader from "./ui/Loader";
import * as actions from "../actions/index";

const stripePromise = loadStripe(
  //"pk_test_51K4swdIkqGuQZn4dQx3v25XoQfu74tjBIJGRSr57GKIKq0hE3vDQhMKk9RNm7fRlszYlA4SgJuKQoELSm3s7k7UR002X0qisQ3"
  "pk_live_51K4swdIkqGuQZn4duwJ7i6XKmx7O9R97Se9CxbWtvUtswVVhf8mArIIcSanbLl4adLbpHdBuZittnk5h4I7eCrrO00P61Lx1br"
);

class App extends React.Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
    this.props.onFetchMemoizeCart();
    this.props.onFetchMemoizeSiteSettings();
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="our-menu" element={<OurMenu />} />
            <Route path="order-now" element={<OrderNow />} />
            <Route path="order-list" element={<OrderList />} />
            <Route path="profile" element={<Profile />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route
              path="checkout"
              element={
                <Elements stripe={stripePromise}>
                  <CheckOut />
                </Elements>
              }
            />
            <Route path="*" element={<NotFound />}></Route>
          </Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="email-verification"
            exact
            element={<EmailVerification />}
          />
        </Routes>

        <SidebarLogin />
        <Loader />
        <ToastContainer />

        {/* <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="about-us" exact element={<AboutUs />} />
          <Route
            path="email-verification"
            exact
            element={<EmailVerification />}
          />
          <Route path="contact-us" exact element={<ContactUs />} />
          <Route path="our-menu" exact element={<OurMenu />} />
          <Route path="order-list" exact element={<OrderList />} />
          <Route path="*" element={<NotFound />}></Route>
        </Routes> */}
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onFetchMemoizeCart: () => dispatch(actions.fetchMemoizeCart()),
    onFetchMemoizeSiteSettings: () =>
      dispatch(actions.fetchMemoizeSiteSettings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
