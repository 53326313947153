import React from "react";
import OtpInput from "react-otp-input";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { reSendMail, signOut, verifyEmail } from "../actions";

class EmailVerification extends React.Component {
  state = { otp: "" };
  handleChange = (otp) => {
    this.setState({ otp });
    if (otp.length === 4) {
      this.props.verifyEmail(otp);
    }
  };

  sendEmail = () => {
    this.props.reSendMail();
  };

  logoutEvent = () => {
    this.props.signOut();
    this.setState({ otp: "" });
  };

  render() {
    const getToken = localStorage.getItem("token");

    if (!getToken || getToken === "undefined" || getToken === "null") {
      return <Navigate to="/" />;
    }

    if (this.props.auth.is_verify === true) {
      return <Navigate to="/" />;
    }
    return (
      <section className="email-verification light-orange-bg d-flex align-items-center justify-content-center">
        <div className="email-verification__box bg-white">
          <h1 className="text-uppercase orange-txt">email verification</h1>
          <p>Enter the code we just send on your email</p>
          <p>
            Email{" "}
            <span className="orange-txt fw500">{this.props.auth.email}</span>
          </p>
          {this.props.auth.error ? (
            <p className="error red">{this.props.auth.error}</p>
          ) : null}
          <OtpInput
            value={this.state.otp}
            onChange={this.handleChange}
            numInputs={4}
            containerStyle="email-verification__otp-form"
            inputStyle="light-orange-bg"
          />
          <h6 className="ffr fw400">Don't receive the code?</h6>
          <h6 className="ffr">
            <button
              className="orange-txt fw700 href-no-bg"
              onClick={this.sendEmail}
            >
              Resend
            </button>
            <br />
            <button
              className="orange-txt fw700 href-no-bg"
              onClick={this.logoutEvent}
            >
              Back To Home
            </button>
          </h6>
        </div>
      </section>
    );
  }
}

const connectMapToState = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(connectMapToState, { reSendMail, signOut, verifyEmail })(
  EmailVerification
);
