import React, { useState } from "react";
import LoginForm from "./forms/LoginForm";
import RegisterForm from "./forms/RegisterForm";
import ForgotPassword from "./forms/ForgotPassword";
import { connect } from "react-redux";
import { sidebarClose } from "../actions";

/** formStatus
 *  1= Login
 *  2= Register
 *  3= Forgot Password
 **/

const SidebarLogin = (props) => {
  const [formStatus, showFormStatus] = useState(1);

  return (
    <div className="sidebar">
      <div className="sidebar__wrap">
        <div
          className={`sidebar__register ${formStatus === 2 ? "" : "d-none"}`}
        >
          <div className="d-flex justify-content-between">
            <div className="sidebar__title">
              <button
                type="button"
                className="sidebar__close-btn"
                onClick={props.sidebarClose}
              >
                <i className="fa fa-times"></i>
              </button>
              <h5 className="ffr">Sign up</h5>
              <p className="fs14">
                or{" "}
                <button
                  className="btn-link sidebar__login-open orange-txt"
                  onClick={() => showFormStatus(1)}
                >
                  login to your account
                </button>
              </p>
            </div>
            <div>
              <img
                src="./images/sign-up.png"
                alt="sign-up"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="sidebar__form">
            <RegisterForm />
          </div>
        </div>

        <div className={`sidebar__login ${formStatus === 1 ? "" : "d-none"}`}>
          <div className="d-flex justify-content-between">
            <div className="sidebar__title">
              <button
                type="button"
                className="sidebar__close-btn"
                onClick={props.sidebarClose}
              >
                <i className="fa fa-times"></i>
              </button>
              <h5 className="ffr">Login</h5>
              <p className="fs14">
                or{" "}
                <button
                  className="btn-link sidebar__register-open orange-txt"
                  onClick={() => showFormStatus(2)}
                >
                  create an account
                </button>
              </p>
            </div>
            <div>
              <img
                src="./images/sign-up.png"
                alt="sign-up"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="sidebar__form">
            <LoginForm />
            <div style={{ marginTop: "10px", cursor: "pointer" }}>
              <span className="orange-txt" onClick={() => showFormStatus(3)}>
                Forgot Password
              </span>
            </div>
          </div>
        </div>

        <div className={`sidebar__login ${formStatus === 3 ? "" : "d-none"}`}>
          <div className="d-flex justify-content-between">
            <div className="sidebar__title">
              <button
                type="button"
                className="sidebar__close-btn"
                onClick={props.sidebarClose}
              >
                <i className="fa fa-times"></i>
              </button>
              <h5 className="ffr">Forgot Password</h5>
              <p className="fs14">
                or{" "}
                <button
                  className="btn-link sidebar__register-open orange-txt"
                  onClick={() => showFormStatus(1)}
                >
                  login to your account
                </button>
              </p>
            </div>
            <div>
              <img
                src="./images/sign-up.png"
                alt="sign-up"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="sidebar__form">
            <ForgotPassword />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  sidebarClose,
})(SidebarLogin);
