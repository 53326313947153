import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import OrderSidebar from "./orders/OrderSidebar";
import { profileForm, fetchUserData } from "../actions";

class Profile extends React.Component {
  componentDidMount() {
    this.props.fetchUserData();
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return <div className="ui error message text-danger">{error}</div>;
    }
  }

  renderInput = ({ input, label, inputId, placeholder, type, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label htmlFor={inputId}>{label}</label>
        <input
          type={type}
          {...input}
          title={label}
          id={inputId}
          autoComplete="off"
          placeholder={placeholder}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.profileForm(formValues);
  };

  render() {
    const getToken = localStorage.getItem("token");
    if (!getToken || getToken === "undefined" || getToken === "null") {
      return <Navigate to="/" />;
    }
    return (
      <div>
        <div className="w-100">
          <img
            src="./images/order-list-bg.png"
            alt="order-list-bg"
            className="img-fluid order-list-bg__image"
          />
        </div>

        <section className="our-menu py-150">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                <div className="our-menu__filter">
                  <OrderSidebar />
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 sidebar__form profile">
                <div className="order-list__wrapper">
                  <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Name"
                      component={this.renderInput}
                      value={this.props.initialValues.name}
                    />
                    <Field
                      name="phone"
                      type="number"
                      placeholder="Phone Number"
                      component={this.renderInput}
                    />
                    <Field
                      name="address"
                      type="text"
                      placeholder="Address"
                      component={this.renderInput}
                    />
                    <Field
                      name="city"
                      type="text"
                      placeholder="City"
                      component={this.renderInput}
                    />
                    <Field
                      name="zipcode"
                      type="text"
                      placeholder="Zipcode"
                      component={this.renderInput}
                    />
                    <button
                      type="submit"
                      className="orange__btn text-uppercase"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.phone) {
    errors.phone = "Enter Mobile Number";
  }

  if (!formValues.address) {
    errors.address = "Enter Address";
  }

  if (!formValues.city) {
    errors.city = "Enter City";
  }

  if (!formValues.zipcode) {
    errors.zipcode = "Enter Zipcode";
  }

  if (!formValues.name) {
    errors.name = "Enter Name";
  }
  return errors;
};

const initialState = reduxForm({
  validate,
  form: "profileForm",
  enableReinitialize: true,
})(Profile);

const mapStateToProps = (state) => {
  return { initialValues: state.profile };
};

export default connect(mapStateToProps, {
  fetchUserData,
  profileForm,
})(initialState);
