import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
//import { Link } from "react-router-dom";
import { fetchOrders } from "../../actions";
import OrderSidebar from "./OrderSidebar";

class OrderList extends React.Component {
  componentDidMount() {
    this.loadMoreOrder();
  }

  loadMoreOrder = () => {
    this.props.fetchOrders();
  };

  renderActiveOrderList() {
    return this.props.orders.activeOrders.map((order, index) => {
      return (
        <div className="order-list__item" key={index}>
          <div className="order-list__item-detail">
            <div className="d-flex justify-content-between mb-4">
              <div className="order-list__item-title">
                <span className="fw500 mr-1">Order #{order.id}</span>
                <span className="fs14">({order.created_at})</span>
              </div>
              <div>
                <span className="order-confirmed d-flex align-items-center fs14">
                  Order {order.order_status}
                  <i className="fa fa-check ml-1" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            {order.order_items.length > 0
              ? order.order_items.map((item, index2) => {
                  return (
                    <div className="d-flex mb-3 item-show" key={index2}>
                      <div>
                        {item.image ? (
                          <img
                            src={item.image}
                            alt="order-item"
                            style={{ width: "120px" }}
                            className="img-fluid order-list__item-image"
                          />
                        ) : null}
                      </div>
                      <div className="flex-fill pl-3">
                        <h5 className="ffr fs16 mb-0">{item.name}</h5>
                        <p className="fs14 mb-0">Qty. {item.qty}</p>
                        <p className="fs14 mb-0">{item.description}</p>
                      </div>
                      <div>
                        <h5 className="ffr fs16">${item.sub_total}</h5>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="order-list__order-detail text-right d-flex justify-content-between">
            <div></div>
            <div className="text-nowrap fs14">
              {order.order_total ? (
                <span className="d-block">Sub Total: ${order.order_total}</span>
              ) : null}
              {order.tax_amount ? (
                <span className="d-block">Taxes: ${order.tax_amount}</span>
              ) : null}
              {order.packing_charge ? (
                <span className="d-block">
                  Order Packing Charges: ${order.packing_charge}
                </span>
              ) : null}
              <span className="fw500 total">
                Total Paid: ${order.final_amount}
              </span>
            </div>
          </div>
          <hr />
          <div className="receiver-details">
            <label>Receiver Details</label>
            <div className="order-list__item-title">
              <span className="fw500">
                Receiver Name: {order.receiver_name}
              </span>
            </div>
            <div className="order-list__item-title">
              <span className="fw500">
                Receiver Phone: {order.receiver_phone}
              </span>
            </div>
          </div>
        </div>
      );
    });
  }

  renderPastOrderList() {
    return this.props.orders.pastOrders.map((order, index) => {
      return (
        <div className="order-list__item" key={index}>
          <div className="order-list__item-detail">
            <div className="d-flex justify-content-between mb-4">
              <div className="order-list__item-title">
                <span className="fw500 mr-1">Order #{order.id}</span>
                <span className="fs14">({order.created_at})</span>
                {order.reject_note ? (
                  <span className="fs14 red error">({order.reject_note})</span>
                ) : null}
              </div>
              <div>
                <span
                  className={`past-ordered d-flex align-items-center fs14 ${
                    order.order_status === `rejected` ? `rejected` : null
                  }`}
                >
                  Order {order.order_status}
                  <i className="fa fa-check ml-1" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            {order.order_items.length > 0
              ? order.order_items.map((item, index2) => {
                  return (
                    <div className="d-flex mb-3 item-show" key={index2}>
                      <div>
                        {item.image ? (
                          <img
                            src={item.image}
                            alt="order-item"
                            style={{ width: "120px" }}
                            className="img-fluid order-list__item-image"
                          />
                        ) : null}
                      </div>
                      <div className="flex-fill pl-3">
                        <h5 className="ffr fs16 mb-0">{item.name}</h5>
                        <p className="fs14 mb-0">Qty. {item.qty}</p>
                        <p className="fs14 mb-0">{item.description}</p>
                      </div>
                      <div>
                        <h5 className="ffr fs16">${item.sub_total}</h5>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="order-list__order-detail text-right d-flex justify-content-between">
            <div>
              {/* <Link to="/" className="orange__btn text-uppercase">
                reorder
              </Link> */}
            </div>
            <div className="text-nowrap fs14">
              {order.order_total ? (
                <span className="d-block">Sub Total: ${order.order_total}</span>
              ) : null}
              {order.tax_amount ? (
                <span className="d-block">Taxes: ${order.tax_amount}</span>
              ) : null}
              {order.packing_charge ? (
                <span className="d-block">
                  Order Packing Charges: ${order.packing_charge}
                </span>
              ) : null}
              <span className="fw500 total">
                Total Paid: ${order.final_amount}
              </span>
            </div>
          </div>
          <hr />
          <div className="receiver-details">
            <label>Receiver Details</label>
            <div className="order-list__item-title">
              <span className="fw500">
                Receiver Name: {order.receiver_name}
              </span>
            </div>
            <div className="order-list__item-title">
              <span className="fw500">
                Receiver Phone: {order.receiver_phone}
              </span>
            </div>
          </div>
        </div>
      );
    });
  }

  render() {
    const getToken = localStorage.getItem("token");
    if (!getToken || getToken === "undefined" || getToken === "null") {
      return <Navigate to="/" />;
    }

    return (
      <div>
        <div className="w-100">
          <img
            src="./images/order-list-bg.png"
            alt="order-list-bg"
            className="img-fluid order-list-bg__image"
          />
        </div>

        <section className="our-menu py-150">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
                <div className="our-menu__filter">
                  <OrderSidebar />
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <div className="order-list__wrapper">
                  {this.props.orders.activeOrders.length > 0 ? (
                    <div>
                      <h2 className="ffr mb-4">Active Order</h2>
                      {this.renderActiveOrderList()}
                    </div>
                  ) : null}

                  {this.props.orders.pastOrders.length > 0 ? (
                    <div className="mt-4">
                      <h2 className="ffr mb-4">Past Order</h2>
                      {this.renderPastOrderList()}
                      {this.props.showLoadMore ? (
                        <div>
                          <button
                            className="orange__btn text-uppercase"
                            onClick={this.loadMoreOrder}
                          >
                            Load More
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : this.props.orders.activeOrders.length === 0 ? (
                    <div className="our-menu__cart-empty text-center">
                      <img
                        src="./images/no-order.png"
                        alt="empty-cart"
                        className="img-fluid"
                      />
                      <p className="fs16 fw500">
                        Looks like you haven't made your order yet
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orderLists,
    showLoadMore: state.orderLists.showLoadMore,
  };
};

export default connect(mapStateToProps, { fetchOrders })(OrderList);
