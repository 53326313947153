import * as actionTypes from "../actions/types";

const INITIAL_STATE = {
  isSignedIn: false,
  userId: null,
  token: null,
  name: null,
  error: null,
  phone: null,
  email: null,
  show_verification: false,
  is_verify: false,
};

const authSuccess = (state, action) => {
  return {
    ...state,
    isSignedIn: true,
    token: action.token,
    userId: action.userId,
    name: action.name,
    phone: action.phone,
    email: action.email,
    error: null,
    is_verify: action.is_verify ? action.is_verify : false,
    show_verification: action.show_verification
      ? action.show_verification
      : false,
  };
};

const authReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.SIGN_OUT:
      return {
        ...state,
        userId: null,
        isSignedIn: false,
        token: null,
        name: null,
        error: null,
        phone: null,
        email: null,
        show_verification: false,
      };
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        userId: null,
        isSignedIn: false,
        token: null,
        name: null,
        email: null,
        phone: null,
        show_verification: action.show_verification,
      };
    default:
      return state;
  }
};

export default authReducers;
