import pitafresh from "../apis/pitafresh";
import * as actionTypes from "./types";
import { reset } from "redux-form";
import _ from "lodash";
import { toast } from "react-toastify";

export const authSuccess = (
  token,
  userId,
  name,
  phone,
  show_verification = false,
  email,
  is_verify = false
) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userId: userId,
    name: name,
    phone: phone,
    email: email,
    show_verification: show_verification,
    is_verify: is_verify,
  };
};

export const authFail = (error, show_verification = false) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
    show_verification: show_verification,
  };
};

export const register = (formValues) => {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await pitafresh.post("/post-registration", formValues);
    if (response.data.IsSuccess) {
      const { api_token, id, name, phone, email } = response.data.Data;
      localStorage.setItem("token", api_token);
      localStorage.setItem("userId", id);
      localStorage.setItem("name", name);
      localStorage.setItem("phone", phone);
      localStorage.setItem("email", email);
      localStorage.setItem("show_verification", false);

      dispatch(authSuccess(api_token, id, name, phone, false, email));

      const cartItems = localStorage.getItem("cartItems");
      if (cartItems && cartItems !== undefined && cartItems !== null) {
        const headers = { Authorization: `Bearer ${api_token}` };
        const response = await pitafresh.post("/cart/add-to-cart", cartItems, {
          headers,
        });
        if (response.data.IsSuccess === true) {
          dispatch(fetchCart());
        }
      } else {
      }

      dispatch(sidebarClose());
      dispatch(reset("loginForm"));
      dispatch(reset("registerForm"));
      dispatch(reset("contactForm"));
      dispatch(reset("forgotPassword"));
    } else {
      dispatch(authFail(response.data.Message));
      toast.error(response.data.Message);
    }
    dispatch(hideLoader());
  };
};

export const contactForm = (formValues) => {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await pitafresh.post("/post-contact", formValues);
    dispatch(hideLoader());
    toast.success(response.data.Message);
    dispatch(reset("contactForm"));
  };
};

export const signIn = (formValues) => {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await pitafresh.post("/post-login", formValues);

    if (response.data.IsSuccess === true) {
      const { api_token, id, name, phone, email } = response.data.Data;

      const is_verification_pending = response.data.is_verification_pending
        ? response.data.is_verification_pending
        : false;
      localStorage.setItem("token", api_token);
      localStorage.setItem("userId", id);
      localStorage.setItem("name", name);
      localStorage.setItem("phone", phone);
      localStorage.setItem("email", email);
      localStorage.setItem("show_verification", is_verification_pending);

      dispatch(
        authSuccess(api_token, id, name, phone, is_verification_pending, email)
      );

      const cartItems = localStorage.getItem("cartItems");
      if (cartItems && cartItems !== undefined && cartItems !== null) {
        const headers = {
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        };
        const response = await pitafresh.post("/cart/add-to-cart", cartItems, {
          headers,
        });
        if (response.data.IsSuccess === true) {
          dispatch(fetchCart());
        }

        localStorage.removeItem("cartItems");
      }

      dispatch(sidebarClose());
      dispatch(reset("loginForm"));
    } else {
      dispatch(authFail(response.data.Message));
      toast.error(response.data.Message);
    }

    dispatch(hideLoader());
  };
};

export const forgotPassword = (formValues) => {
  return async (dispatch) => {
    dispatch(showLoader());
    const response = await pitafresh.post("/post-forgot-password", formValues);

    if (response.data.IsSuccess === true) {
      toast.success(response.data.Message);
      dispatch(reset("forgotPassword"));
      dispatch(sidebarClose());
    } else {
      toast.error(response.data.Message);
    }
    dispatch(hideLoader());
  };
};

export const signOut = () => (dispatch) => {
  dispatch(showLoader());

  const getToken = localStorage.getItem("token");
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("name");
  localStorage.removeItem("phone");
  localStorage.removeItem("email");
  localStorage.removeItem("show_verification");

  dispatch({ type: actionTypes.SIGN_OUT });
  dispatch(hideLoader());
  dispatch({
    type: actionTypes.FETCH_CART_ITEMS,
    payload: [],
  });

  if (getToken && getToken !== "undefined" && getToken !== "null") {
    const headers = { Authorization: `Bearer ${getToken}` };
    pitafresh.get("/get-logout", { headers });
  }
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token || token === "undefined" || token === "null") {
      dispatch(signOut());
    } else {
      const userId = localStorage.getItem("userId");
      const name = localStorage.getItem("name");
      const phone = localStorage.getItem("phone");
      const email = localStorage.getItem("email");
      const show_verification = localStorage.getItem("show_verification");

      dispatch(
        authSuccess(token, userId, name, phone, show_verification, email)
      );
    }
    dispatch(fetchCart());
  };
};

export const sidebarOpen = () => {
  return {
    type: actionTypes.SIDEBAR_OPEN,
  };
};

export const sidebarClose = () => {
  return {
    type: actionTypes.SIDEBAR_CLOSE,
  };
};

export const fetchOrders = () => {
  return async (dispatch, getState) => {
    dispatch(showLoader());
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const headers = { Authorization: `Bearer ${getToken}` };
      const response = await pitafresh
        .post(
          "/order/history",
          { offset: getState().orderLists.pastOrders.length },
          { headers }
        )
        .catch(function (error) {
          if (error.response.status === 401) {
            dispatch(signOut());
            window.location.reload();
          }
        });

      if (response && response.data.IsSuccess === true) {
        dispatch({
          type: actionTypes.FETCH_ORDERS,
          payload: response.data.Data,
        });
      }
    }

    dispatch(hideLoader());
  };
};

export const addToCart = (
  id,
  qty,
  item_variant_category_map = [],
  updateId = 0
) => {
  return async (dispatch, getState) => {
    dispatch(showLoader());
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const headers = { Authorization: `Bearer ${getToken}` };
      const postData = [
        {
          item_id: id,
          qty: qty,
          item_variant_item_map_id: item_variant_category_map,
          id: updateId,
        },
      ];
      const response = await pitafresh.post("/cart/add-to-cart", postData, {
        headers,
      });
      if (response.data.IsSuccess === true) {
        dispatch(fetchCart());
      }
    }
    dispatch(hideLoader());
  };
};

export const fetchCart = () => {
  return async (dispatch) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const headers = { Authorization: `Bearer ${getToken}` };
      const response = await pitafresh.get("/cart/items", { headers });
      if (response.data.IsSuccess === true) {
        dispatch({
          type: actionTypes.FETCH_CART_ITEMS,
          payload: response.data.Data,
        });
      }
    } else {
      const cartItems = localStorage.getItem("cartItems");
      if (cartItems && cartItems !== undefined && cartItems !== null) {
        dispatch({
          type: actionTypes.FETCH_CART_ITEMS,
          payload: JSON.parse(cartItems),
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_CART_ITEMS,
          payload: [],
        });
      }
    }
  };
};

export const emptyCart = () => async (dispatch) =>
  dispatch({
    type: actionTypes.FETCH_CART_ITEMS,
    payload: [],
  });

const _fetchMemoizeCart = _.memoize(async (dispatch) => {
  const getToken = localStorage.getItem("token");
  if (getToken && getToken !== "undefined" && getToken !== "null") {
    const headers = { Authorization: `Bearer ${getToken}` };
    const response = await pitafresh.get("/cart/items", { headers });
    if (response.data.IsSuccess === true) {
      dispatch({
        type: actionTypes.FETCH_CART_ITEMS,
        payload: response.data.Data,
      });
    }
  }
});
export const fetchMemoizeCart = () => (dispatch) => _fetchMemoizeCart(dispatch);

export const showLoader = () => {
  return {
    type: actionTypes.SHOW_LOADER,
  };
};

export const hideLoader = () => {
  return {
    type: actionTypes.HIDE_LOADER,
  };
};

export const fetchMenu = (searchItem = "") => {
  return async (dispatch, getState) => {
    dispatch(showLoader());

    let headers = {};
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      headers = { Authorization: `Bearer ${getToken}` };
    }

    const response = await pitafresh.post(
      "post-menu",
      { search_params: searchItem },
      { headers }
    );

    if (response && response.data.IsSuccess === true) {
      dispatch({ type: actionTypes.ORDER_MENU, payload: response.data.Data });
    }

    dispatch(hideLoader());
  };
};

export const reSendMail = () => {
  return async (dispatch) => {
    dispatch(showLoader());

    let headers = {};
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      headers = { Authorization: `Bearer ${getToken}` };
      await pitafresh.post(
        "post-resend-email",
        { api_token: getToken },
        { headers }
      );
    }
    dispatch(hideLoader());
  };
};

export const verifyEmail = (otp = 1111) => {
  return async (dispatch, getState) => {
    dispatch(showLoader());

    let headers = {};
    const getToken = localStorage.getItem("token");

    const { token, userId, name, phone, email } = getState().auth;

    if (getToken && getToken !== "undefined" && getToken !== "null") {
      headers = { Authorization: `Bearer ${getToken}` };
      const response = await pitafresh.post(
        "post-email-verification",
        { api_token: getToken, token: otp },
        { headers }
      );

      if (response.data.IsSuccess === true) {
        localStorage.setItem("show_verification", false);
        dispatch(authSuccess(token, userId, name, phone, false, email, true));

        const cartItems = localStorage.getItem("cartItems");
        if (cartItems && cartItems !== undefined && cartItems !== null) {
          const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };
          const response = await pitafresh.post(
            "/cart/add-to-cart",
            cartItems,
            {
              headers,
            }
          );
          if (response.data.IsSuccess === true) {
            dispatch(fetchCart());
          }
          localStorage.removeItem("cartItems");
        }
      } else {
        dispatch(authFail(response.data.Message));
      }
    }
    dispatch(hideLoader());
  };
};

const _fetchMemoizeSiteSettings = _.memoize(async (dispatch) => {
  const response = await pitafresh.get("/get-store-details");
  if (response.data.IsSuccess === true) {
    dispatch({
      type: actionTypes.SITE_SETTINGS,
      payload: response.data.Data,
    });
  }
});

export const fetchMemoizeSiteSettings = () => (dispatch) =>
  _fetchMemoizeSiteSettings(dispatch);

export const fetchUserData = () => {
  return async (dispatch) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      dispatch(showLoader());
      const headers = { Authorization: `Bearer ${getToken}` };
      const response = await pitafresh.get("/profile", { headers });

      if (response.data.IsSuccess === true) {
        dispatch({
          type: actionTypes.GET_PROFILE,
          payload: response.data.Data,
        });
      } else {
        toast.error(response.data.Message);
      }
      dispatch(hideLoader());
    }
  };
};

export const profileForm = (formValues) => {
  return async (dispatch) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      dispatch(showLoader());
      const headers = { Authorization: `Bearer ${getToken}` };

      const response = await pitafresh.post(
        "/profile/post-update",
        formValues,
        { headers }
      );

      if (response.data.IsSuccess === true) {
        dispatch({
          type: actionTypes.GET_PROFILE,
          payload: response.data.Data,
        });
      } else {
        toast.error(response.data.Message);
      }
      dispatch(hideLoader());
    }
  };
};

export const changePassword = (formValues) => {
  return async (dispatch) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      dispatch(showLoader());
      const headers = { Authorization: `Bearer ${getToken}` };

      const response = await pitafresh.post(
        "/profile/post-change-password",
        formValues,
        { headers }
      );

      if (response.data.IsSuccess === true) {
        toast.success(response.data.Message);
        dispatch(reset("changePassword"));
        /* dispatch(signOut()); */
      } else {
        toast.error(response.data.Message);
      }
      dispatch(hideLoader());
    }
  };
};
