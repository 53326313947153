import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import pitafresh from "../apis/pitafresh";
import {
  fetchCart,
  addToCart,
  showLoader,
  hideLoader,
  emptyCart,
} from "../actions";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

class CheckOut extends React.Component {
  state = {
    isRedirect: false,
    redirectURL: "",
    showVarient: false,
    activeItem: null,
    searchItem: "",
    activeCategory: "",
    name: "",
    phone: "",
    email:"",
    note: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchCart();
    this.setState({ name: this.props.auth.name, phone: this.props.auth.phone, email:this.props.auth.email });
  }

  increaseItemInCart = (item) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const qty = (item.qty += 1);
      this.props.addToCart(item.item_id, qty, [], item.id);
    } else {
      const storageId = item.local_storage_id;

      let cartItems = localStorage.getItem("cartItems");
      if (cartItems && cartItems !== null && cartItems !== undefined) {
        const cartData = JSON.parse(cartItems);

        let updatedItemList = cartData.map((item) => {
          if (item.local_storage_id === storageId) {
            return { ...item, qty: item.qty + 1 };
          }
          return item;
        });

        localStorage.setItem("cartItems", JSON.stringify(updatedItemList));
        this.props.fetchCart();
      } else {
        console.log("Test");
      }
    }
  };

  filterInPlace = (array, predicate) => {
    let end = 0;

    for (let i = 0; i < array.length; i++) {
      const obj = array[i];

      if (predicate(obj)) {
        array[end++] = obj;
      }
    }

    array.length = end;
  };

  decreaseItemInCart = (item) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const qty = (item.qty -= 1);
      this.props.addToCart(item.item_id, qty, [], item.id);
    } else {
      const storageId = item.local_storage_id;
      let cartItems = localStorage.getItem("cartItems");
      if (cartItems && cartItems !== null && cartItems !== undefined) {
        const cartData = JSON.parse(cartItems);
        let filteredDataSource = cartData.filter((item) => {
          if (item.local_storage_id === storageId) {
            item.qty -= 1;
          }
          return item;
        });

        this.filterInPlace(filteredDataSource, (item) => item.qty !== 0);

        localStorage.setItem("cartItems", JSON.stringify(filteredDataSource));
        this.props.fetchCart();
      } else {
        console.log("Test");
      }
    }
  };

  placeOrderEventHandler = async (event) => {
    event.preventDefault();

    if (!this.state.name) {
      if(this.props.auth.name){
        this.setState({name:this.props.auth.name})
      }else{
        toast.error("Please enter name");
        return;
      }
    }
    if (!this.state.phone) {
      if(this.props.auth.phone){
        this.setState({phone:this.props.auth.phone})
      }else{
        toast.error("Please enter phone");
        return;
      }
    }
    if (!this.state.email) {
      if(this.props.auth.email){
        this.setState({email:this.props.auth.email})
      }else{
        toast.error("Please enter email");
        return;
      }
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      toast.error("Invalid email address");
      return;
    }

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      console.log("here");
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      if (result.error.message) {
        toast.error(result.error.message);
      }
    } else {
      this.props.showLoader();
      const cardToken = result.token;

      const getToken = localStorage.getItem("token");
      if (getToken && getToken !== "undefined" && getToken !== "null") {
        const headers = { Authorization: `Bearer ${getToken}` };
        const response = await pitafresh.post(
          "/cart/checkout",
          {
            card_details: cardToken,
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            note: this.state.note,
          },
          {
            headers,
          }
        );
        if (response.data.IsSuccess === true) {
          this.setState({
            isRedirect: true,
            redirectURL: "/order-list",
          });
          this.props.emptyCart();
          toast.success(response.data.Message);
        } else {
          toast.error(response.data.Message);
        }
      } else {
        //toast.error("Please login before checkout");
        const response2 = await pitafresh.post("/cart/checkout", {
          card_details: cardToken,
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          note: this.state.note,
          cart: this.props.cartItems,
        });
        if (response2.data.IsSuccess === true) {
          this.setState({
            isRedirect: true,
            redirectURL: "/order-list",
          });
          this.props.emptyCart();
          toast.success(response2.data.Message);
        } else {
          toast.error(response2.data.Message);
        }
      }
      this.props.hideLoader();
    }
  };

  renderCardDetail = () => {
    return this.props.cartItems.map((item, index) => {
      return (
        <tr className="grey-txt" key={index}>
          <td className="py-1 pl-0 fs14">
            {item.name} <br />
            {item.description}
          </td>
          <td className="py-1 pl-0">
            <div className="our-menu__list-item-button d-flex align-items-center justify-content-between">
              <button
                style={{ cursor: "pointer" }}
                onClick={() => this.decreaseItemInCart(item)}
                type="button"
                className="quantity-minus bg-transparent fs16"
              >
                <i className="fa fa-minus"></i>
              </button>
              <span className="text-center fw500 fs16 price-box">
                {item.qty}
              </span>
              <button
                style={{ cursor: "pointer" }}
                onClick={() => this.increaseItemInCart(item)}
                type="button"
                className="quantity-plus bg-transparent fs16"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </td>
          <td className="py-1 pl-0 fs14 text-right">
            ${parseFloat(item.sub_total).toFixed(2)}
          </td>
        </tr>
      );
    });
  };

  render() {
    /* const getToken = localStorage.getItem("token");
    if (!getToken || getToken === "undefined" || getToken === "null") {
      return <Navigate to="/order-now" />;
    } */

    if (this.state.isRedirect) {
      return <Navigate to={this.state.redirectURL} />;
    }
    const itemTotal = parseFloat(
      this.props.cartItems.reduce(function (prev, current) {
        return prev + +parseFloat(current.sub_total);
      }, 0)
    ).toFixed(2);

    const taxTotal = parseFloat((itemTotal * this.props.foodTax) / 100).toFixed(
      2
    );
    const finalTotal = parseFloat(
      parseFloat(itemTotal) + parseFloat(taxTotal)
    ).toFixed(2);
    return (
      <>
        <div className="w-100">
          <img
            src="./images/order-list-bg.png"
            alt="order-list-bg"
            className="img-fluid order-list-bg__image"
          />
        </div>

        <section
          className="our-menu py-150"
          style={{
            backgroundImage: "url('images/contact-form-bg.png')",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-10 col-lg-7 col-xl-6">
                <div className="pay__box">
                  <h4 className="ffr orange-txt fw700">Payment</h4>
                  <div className="our-menu__cart">
                    <table className="table table-borderless">
                      <tbody>
                        {this.renderCardDetail()}

                        <tr className="border-top">
                          <td
                            colSpan="4"
                            className="fs14 text-left pt-3 pb-2 pl-0"
                          >
                            <b>Bill details</b>
                          </td>
                        </tr>
                        <tr className="grey-txt">
                          <td colSpan="2" className="py-1 pl-0 fs14 text-left">
                            Item Total
                          </td>
                          <td colSpan="2" className="py-1 pl-0 fs14 text-right">
                            ${itemTotal}
                          </td>
                        </tr>
                        <tr className="grey-txt">
                          <td colSpan="2" className="py-1 pl-0 fs14 text-left">
                            Tax Total
                          </td>
                          <td colSpan="2" className="py-1 pl-0 fs14 text-right">
                            ${taxTotal}
                          </td>
                        </tr>
                        <tr className="border-top">
                          <td
                            colSpan="2"
                            className="pt-3 pb-1 pl-0 text-left fs18"
                          >
                            <span>TO PAY</span>
                          </td>
                          <td colSpan="2" className="pt-3 pb-1 text-right fs18">
                            <span>${finalTotal}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="checkout__box mt-4">
                    <h6 className="ffr fw700">Pickup Person Details</h6>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 fs14">
                        <input
                          type="number"
                          id="phone"
                          value={
                            this.state.phone
                          }
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          placeholder="Phone"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 fs14">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                          value={
                            this.state.name
                          }
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 fs14">
                        <input
                          type="email"
                          id="email"
                          value={
                            this.state.email
                          }
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          placeholder="Email"
                        />
                      </div>
                    </div>

                    <div className="checkout__box row">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <textarea
                          className="form-control"
                          onChange={(e) =>
                            this.setState({ note: e.target.value })
                          }
                          value={this.state.note}
                          placeholder="Any note"
                        />
                      </div>
                    </div>
                    <div className="checkout__box mt-4">
                      <h6 className="ffr fw700">Add card details</h6>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 fs14">
                          <CardElement options={CARD_ELEMENT_OPTIONS} />

                          <button
                            type="submit"
                            style={{ marginTop: "20px" }}
                            className="text-uppercase orange__btn"
                            onClick={this.placeOrderEventHandler}
                            disabled={
                              this.props.cartItems.length <= 0 ||
                              !this.props.stripe
                            }
                          >
                            PAY ${finalTotal}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    cartItems: state.cartDetail.cartItems,
    foodTax: state.settings.storeDetails.tax_on_food,
  };
};

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckOut stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

export default connect(mapStateToProps, {
  fetchCart,
  addToCart,
  showLoader,
  hideLoader,
  emptyCart,
})(InjectedCheckoutForm);
