export const STORE_DETAIL = "STORE_DETAIL";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_IN = "SIGN_IN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const SIDEBAR_OPEN = "SIDEBAR_OPEN";
export const SIDEBAR_CLOSE = "SIDEBAR_CLOSE";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const ORDER_MENU = "ORDER_MENU";
export const FETCH_CART_ITEMS = "FETCH_CART_ITEMS";
export const PLACE_ORDER = "PLACE_ORDER";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const SITE_SETTINGS = "SITE_SETTINGS";
export const GET_PROFILE = "GET_PROFILE";
