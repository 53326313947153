import React from "react";
import { Link } from "react-router-dom";

const Notfound = () => {
  return (
    <div>
      <div
        className="internal__banner"
        style={{ backgroundImage: "url('images/about-bg.png')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="content__item mt-5">
                <h1 className="text-uppercase text-center">error 404</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="error-section section py-100">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="error-section__content item-bounce">
                <img src="./images/404.png" alt="404" className="img-fluid" />
                <h2 className="text-uppercase ffr fw700 orange-txt mb-2">
                  ERROR
                </h2>
                <h4 className="ffr fw500 text-uppercase">page not found</h4>
              </div>
              <Link to="/" className="green__btn">
                go to homepage
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Notfound;
