import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import { fetchMenu, addToCart, fetchCart } from "../actions";

class OrderNow extends React.Component {
  state = {
    isRedirect: false,
    redirectURL: "/",
    showVarient: false,
    activeItem: null,
    searchItem: "",
    activeCategory: "Picked for you",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchMenu();
    //this.props.fetchCart();
  }

  addItemToCart = (item) => {
    if(this.props.settings.storeDetails.store_status == 1){
      const getToken = localStorage.getItem("token");
      if (
        item.item_variant_category_map &&
        item.item_variant_category_map.length > 0
      ) {
        this.setState({ showVarient: true, activeItem: item });
      } else {
        if (getToken && getToken !== "undefined" && getToken !== "null") {
          this.props.addToCart(item.id, 1);
        } else {
          let cartItems = localStorage.getItem("cartItems");
          if (cartItems === null || cartItems === undefined) {
            const cartData = JSON.stringify([
              {
                item_id: item.id,
                name: item.name,
                price: item.price,
                sub_total: item.price * 1,
                description: item.description,
                service_type: item.service_type,
                food_category: item.food_category,
                qty: 1,
                id: 0,
                local_storage_id: 1,
              },
            ]);
            localStorage.setItem("cartItems", cartData);
          } else {
            const cartData = JSON.parse(cartItems);
            cartData.push({
              item_id: item.id,
              name: item.name,
              price: item.price,
              sub_total: item.price * 1,
              description: item.description,
              service_type: item.service_type,
              food_category: item.food_category,
              qty: 1,
              id: 0,
              local_storage_id: Date.parse(new Date()),
            });
            localStorage.setItem("cartItems", JSON.stringify(cartData));
          }
          this.props.fetchCart();
        }

        toast.success("Item Added");
      }
    }else{
      toast.error("Store is closed");
    }
  };

  addItemWithVarient = (item) => {
    let keys = [],
      totalPrice = item.price;
    Object.entries(item.item_variant_category_map).forEach(([key, value]) => {
      if (value.is_multiple) {
        if (
          window.jQuery(
            'input:checkbox[name="item_name_' + value.id + '[]"]:checked'
          ).length > 0
        ) {
          window
            .jQuery(
              'input:checkbox[name="item_name_' + value.id + '[]"]:checked'
            )
            .each(function () {
              const varientPrice = +parseFloat(
                window.jQuery(this).attr("customprice")
              ).toFixed(2);
              totalPrice += varientPrice;
              keys.push(window.jQuery(this).val());
            });
        }
      } else {
        if (
          window
            .jQuery('input[name="item_name_' + value.id + '"]:checked')
            .val() !== undefined &&
          window
            .jQuery('input[name="item_name_' + value.id + '"]:checked')
            .val() !== null
        ) {
          const varientPrice = +parseFloat(
            window
              .jQuery('input[name="item_name_' + value.id + '"]:checked')
              .attr("customprice")
          ).toFixed(2);
          totalPrice += varientPrice;
          keys.push(
            /* document.querySelector(
              'input[name="item_name_' + value.id + '"]:checked'
            ).value */

            window
              .jQuery('input[name="item_name_' + value.id + '"]:checked')
              .val()
          );
        }
      }
    });

    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      this.props.addToCart(item.id, 1, keys);
    } else {
      let cartItems = localStorage.getItem("cartItems");
      if (cartItems === null || cartItems === undefined) {
        const cartData = [
          {
            item_id: item.id,
            name: item.name,
            price: totalPrice,
            sub_total: totalPrice * 1,
            description: item.description,
            service_type: item.service_type,
            food_category: item.food_category,
            qty: 1,
            id: 0,
            local_storage_id: Date.parse(new Date()),
            item_variant_item_map_id: keys,
          },
        ];
        localStorage.setItem("cartItems", JSON.stringify(cartData));
      } else {
        const cartData = JSON.parse(cartItems);
        cartData.push({
          item_id: item.id,
          name: item.name,
          price: totalPrice,
          sub_total: totalPrice * 1,
          description: item.description,
          service_type: item.service_type,
          food_category: item.food_category,
          qty: 1,
          id: 0,
          local_storage_id: Date.parse(new Date()),
          item_variant_item_map_id: keys,
        });
        localStorage.setItem("cartItems", JSON.stringify(cartData));
      }
      this.props.fetchCart();
    }
    toast.success("Item Added");
    //Hide Varient Modal
    this.setState({ showVarient: false, activeItem: null });
  };

  increaseItemInCart = (item) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const qty = (item.qty += 1);
      this.props.addToCart(item.item_id, qty, [], item.id);
    } else {
      const storageId = item.local_storage_id;

      let cartItems = localStorage.getItem("cartItems");
      if (cartItems && cartItems !== null && cartItems !== undefined) {
        const cartData = JSON.parse(cartItems);

        let updatedItemList = cartData.map((item) => {
          if (item.local_storage_id === storageId) {
            return {
              ...item,
              qty: item.qty + 1,
              sub_total: item.price * (item.qty + 1),
            };
          }
          return item;
        });

        localStorage.setItem("cartItems", JSON.stringify(updatedItemList));
        this.props.fetchCart();
      } else {
        toast.error("Test");
      }
    }
  };

  filterInPlace = (array, predicate) => {
    let end = 0;

    for (let i = 0; i < array.length; i++) {
      const obj = array[i];

      if (predicate(obj)) {
        array[end++] = obj;
      }
    }

    array.length = end;
  };

  decreaseItemInCart = (item) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const qty = (item.qty -= 1);
      this.props.addToCart(item.item_id, qty, [], item.id);
    } else {
      const storageId = item.local_storage_id;
      let cartItems = localStorage.getItem("cartItems");
      if (cartItems && cartItems !== null && cartItems !== undefined) {
        const cartData = JSON.parse(cartItems);
        let filteredDataSource = cartData.filter((item) => {
          if (item.local_storage_id === storageId) {
            item.sub_total = item.price * (item.qty - 1);
            item.qty -= 1;
          }
          return item;
        });

        this.filterInPlace(filteredDataSource, (item) => item.qty !== 0);

        localStorage.setItem("cartItems", JSON.stringify(filteredDataSource));
        this.props.fetchCart();
      } else {
        toast.error("Test");
      }
    }
  };

  renderDesktopItemLists = () => {
    return this.props.items.map((item, index) => {
      return (
        <div key={index} id={item.name.split(/\s/).join("")}>
          <div className="our-menu__list-title">
            <h4 className="orange-txt fw700 mb-2 ffr">{item.name}</h4>
            <span className="text-uppercase grey-txt">
              {item.items.length} items
            </span>
          </div>

          {item.items.map((singleItem, index) => {
            return (
              <div
                className="our-menu__list-item py-4 d-flex align-items-center justify-content-between added-in-cart"
                key={index}
              >
                <div className="our-menu__list-item-content">
                  {singleItem.is_bestseller ? (
                    <div className="mb-2 orange-txt bestseller-txt">
                      <i className="fa fa-star"></i> Bestseller
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <img
                      src={singleItem.food_category_icon}
                      alt={singleItem.name}
                      className="img-fluid"
                      style={{ width: "16px", height: "16px" }}
                    />
                    <h6 className="fw500 ffr ml-2 mr-2">{singleItem.name}</h6>
                  </div>

                  <span className="fs14">${singleItem.price}</span>
                  <p className="grey-txt fw400 mt-3 mb-0 fs14">
                    {singleItem.description}
                  </p>
                </div>
                <div>
                  {singleItem.service_type !== "Dine-in" ? (
                    singleItem.isSelected ? (
                      <div className="our-menu__list-item-button d-flex align-items-center justify-content-between">
                        <button
                          type="button"
                          className="quantity-minus bg-transparent fs16"
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        <span className="text-center fw500 fs16 green-txt">
                          {singleItem.order_qty}
                        </span>
                        <button
                          type="button"
                          className="quantity-plus bg-transparent fs16"
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    ) : (
                      <div className="our-menu__list-item-with-image text-center">
                        {singleItem.image ? (
                          <img
                            src={singleItem.image}
                            alt="plate"
                            className="img-fluid d-block radius5"
                            style={{ width: "120px" }}
                          />
                        ) : null}
                        <button
                          onClick={() => this.addItemToCart(singleItem)}
                          type="button"
                          className="our-menu__list-item-add-to-cart bg-white fw500 green-txt"
                        >
                          Add
                        </button>
                        {singleItem.item_variant_category_map.length > 0 ? (
                          <div className="customise-text">Customisable</div>
                        ) : null}
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  renderMobileItemLists = () => {
    return this.props.items.map((item, index) => {
      return (
        <div className="card" key={index}>
          <div className="card-header" id={`menuhead${index}`}>
            <button
              className="btn-header-link active"
              data-toggle="collapse"
              data-target={`#menu${index}`}
              aria-controls={`menu${index}`}
            >
              {item.name} ({item.items.length})
            </button>
          </div>

          <div
            id={`menu${index}`}
            className="collapse show"
            aria-labelledby={`menuhead${index}`}
            data-parent="#menu"
          >
            <div className="card-body p-0">
              {item.items.map((singleItem, index) => {
                return (
                  <div
                    className="our-menu__list-item py-4 d-flex align-items-center justify-content-between"
                    key={index}
                  >
                    <div className="our-menu__list-item-content">
                      <div className="item-info">
                        {singleItem.is_bestseller ? (
                          <span className="ml-2 orange-txt">
                            <i className="fa fa-star"></i>Bestseller
                          </span>
                        ) : null}
                      </div>
                      <h6 className="fw500 ffr my-3">
                        <img
                          src={singleItem.food_category_icon}
                          alt="category icon"
                          className="img-fluid"
                          style={{ width: "18px", height: "18px" }}
                        />
                        <span style={{ marginLeft: "5px" }}>
                          {singleItem.name}
                        </span>
                      </h6>
                      <span className="fs14">${singleItem.price}</span>
                      <p className="grey-txt fw400 mt-3 mb-0 fs14">
                        {singleItem.description}
                      </p>
                    </div>
                    {singleItem.service_type !== "Dine-in" ? (
                      <div>
                        <button
                          type="button"
                          onClick={() => this.addItemToCart(singleItem)}
                          className="our-menu__list-item-add-to-cart bg-white fw500 green-txt"
                        >
                          Add
                        </button>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  renderCategoryItems = () => {
    return (
      <div className="our-menu__filter d-none d-md-block">
        <ul className="m-0 p-0">
          {this.props.items.map((item, index) => {
            return (
              <li
                className="d-block"
                key={index}
                onClick={() => {
                  this.changeActiveCategory(item);
                }}
              >
                <button
                  type="button"
                  className={`text-left p-2 w-100 ${
                    item.name === this.state.activeCategory ? "active" : ""
                  }`}
                >
                  {item.name}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  changeActiveCategory = (item) => {
    this.setState({ activeCategory: item.name });

    window.jQuery("html, body").animate(
      {
        scrollTop:
          window.jQuery("#" + item.name.split(/\s/).join("") + "").offset()
            .top -
          (window.jQuery("#header").outerHeight() + 20),
      },
      10
    );
  };

  renderCardDetail = () => {
    return this.props.cartItems.map((item, index) => {
      return (
        <tr className="grey-txt" key={index}>
          <td className="py-1 pl-0 fs14">{item.name}</td>
          <td className="py-1 pl-0">
            <div className="our-menu__list-item-button d-flex align-items-center justify-content-between">
              <button
                style={{ cursor: "pointer" }}
                onClick={() => this.decreaseItemInCart(item)}
                type="button"
                className="quantity-minus bg-transparent fs16"
              >
                <i className="fa fa-minus"></i>
              </button>
              <span className="text-center fw500 fs16 price-box">
                {item.qty}
              </span>
              <button
                style={{ cursor: "pointer" }}
                onClick={() => this.increaseItemInCart(item)}
                type="button"
                className="quantity-plus bg-transparent fs16"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </td>
          <td className="py-1 pl-0 fs14 text-right">
            ${parseFloat(item.sub_total).toFixed(2)}
          </td>
        </tr>
      );
    });
  };

  getFilteredItems = (e) => {
    e.preventDefault();
    const searchItem = this.state.searchItem;
    this.props.fetchMenu(searchItem);
  };

  onAddingItem = (varient) => {
    /* const varientTarget = varient.target;
    const itemMapId = parseInt(varientTarget.getAttribute("htmlitemid"));
    const isChecked = varientTarget.checked;
    const activeOrderId = this.state.activeItem.id;
    const isMultiple = varientTarget.htmlmultiple;

    const orderDetail = {
      item_id: activeOrderId,
      qty: 1,
    };

    if (
      this.state &&
      this.state.orderDetail &&
      this.state.orderDetail.item_id === activeOrderId
    ) {
      orderDetail.item_variant_item_map_id =
        this.state.orderDetail.item_variant_item_map_id;
      if (isChecked === false) {
        const index = orderDetail.item_variant_item_map_id.indexOf(itemMapId);
        if (index > -1) {
          orderDetail.item_variant_item_map_id.splice(index, 1);
        }
      } else {
        orderDetail.item_variant_item_map_id.push(itemMapId);
      }
    } else {
      orderDetail.item_variant_item_map_id = [itemMapId];
    }

    this.setState({ orderDetail: orderDetail }); */
    //console.log(varient.target.dataId);
  };

  onAddingRadioItem = (varient, itemCategory) => {
    let activeItem = this.state.activeItem;
    activeItem.item_variant_category_map.filter((item) => {
      if (item.id === itemCategory.id) {
        return item.item_variant_item_map.map((itemMap) => {
          if (varient.id === itemMap.id) {
            itemMap.variant_item.is_checked = true;
          } else {
            itemMap.variant_item.is_checked = false;
          }
          return itemMap;
        });
      }
      return item;
    });

    this.setState({ activeItem });
  };

  renderVarientPopup = () => {
    const item = this.state.activeItem;
    console.log(item);
    return (
      <div className="menu-varients__modal active">
        <div className="menu-varients__box">
          <div className="menu-varients__box-row d-flex">
            <div className="mr-3">
              <img src={item.food_category_icon} alt="non-veg" />
            </div>
            <div>
              <h6 className="ffr fw500">Customize {item.name}</h6>
              <span className="ffr fs14">${item.price}</span>
            </div>
            <div className="close-btn" style={{ marginLeft: "auto" }}>
              <button className="href-no-bg" onClick={this.closeVarientModal}>
                <i className="fa fa-times fa-2x"></i>
              </button>
            </div>
          </div>

          {item.item_variant_category_map.map((itemCategory, categoryIndex) => {
            return (
              <div key={categoryIndex}>
                <h2 className="ffr">{itemCategory.variant_category.name}</h2>
                {itemCategory.item_variant_item_map.map((varient, index) => {
                  return (
                    <div key={index}>
                      {itemCategory.is_multiple ? (
                        <div className="menu-varients__box-row">
                          <div>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                customprice={varient.price}
                                name={`item_name_${varient.item_variant_category_map_id}[]`}
                                onChange={(varient) =>
                                  this.onAddingItem(varient)
                                }
                                /* htmldataid={itemCategory.id}
                                htmlitemid={varient.id}
                                htmlmultiple={1}
                                onChange={this.onAddingItem(varient)} */
                                id={`${itemCategory.id}_item_${index}`}
                                value={varient.id}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`${itemCategory.id}_item_${index}`}
                              >
                                {varient.variant_item.name} ${varient.price}
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="menu-varients__box-row" key={index}>
                          <div>
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                checked={varient.variant_item.is_checked}
                                customprice={varient.price}
                                onChange={() =>
                                  this.onAddingRadioItem(varient, itemCategory)
                                }
                                className="custom-control-input"
                                /* htmldataid={itemCategory.id}
                                htmlitemid={varient.id}
                                htmlmultiple={0} 
                                onChange={(varient) =>
                                  this.onAddingItem(varient)
                                }
                                */
                                name={`item_name_${varient.item_variant_category_map_id}`}
                                id={`${itemCategory.id}_item_${index}`}
                                value={varient.id}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`${itemCategory.id}_item_${index}`}
                              >
                                {varient.variant_item.name} ${varient.price}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <button
            className="text-center"
            type="submit"
            onClick={() => this.addItemWithVarient(item)}
          >
            {/* <span>Total $11.49</span> */}
            <span>ADD ITEM</span>
          </button>
        </div>
      </div>
    );
  };

  closeVarientModal = () =>
    this.setState({ showVarient: false, activeItem: null });

  checkOutEventHandler = () => {
    /* if (this.props.isSignedIn === false) {
      toast.error("Please login before chekout");
      return;
    } */
    this.setState({ isRedirect: true, redirectURL: "/checkout" });
  };

  render() {
    if (this.state.isRedirect) {
      return <Navigate to={this.state.redirectURL} />;
    }
    return (
      <div>
        {this.state.showVarient ? this.renderVarientPopup() : null}
        <div
          className="internal__banner"
          style={{ backgroundImage: "url('images/about-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="content__item mt-5">
                  <h1 className="text-uppercase text-center">Order Now</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="our-menu py-150">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                <div className="d-block d-md-none mb-5">
                  <form onSubmit={this.getFilteredItems}>
                    <div className="our-menu__search d-flex align-items-center">
                      <input
                        type="text"
                        name=""
                        id="searchDishes"
                        placeholder="Search for dishes"
                        className="w-100 py-2"
                        value={this.state.searchItem}
                        onChange={(e) =>
                          this.setState({ searchItem: e.target.value })
                        }
                      />
                      <button
                        type="submit"
                        className="bg-transparent"
                        onClick={this.getFilteredItems}
                      >
                        <h5 className="m-0">
                          <i className="fa fa-search"></i>
                        </h5>
                      </button>
                    </div>
                  </form>
                </div>

                {this.renderCategoryItems()}
              </div>

              <div className="col-12 col-sm-12 col-md-9 col-lg-7 col-xl-7">
                <div className="our-menu__list-container">
                  <div className="d-none d-md-block">
                    <form onSubmit={this.getFilteredItems}>
                      <div className="our-menu__search d-flex align-items-center">
                        <input
                          type="text"
                          value={this.state.searchItem}
                          onChange={(e) =>
                            this.setState({ searchItem: e.target.value })
                          }
                          placeholder="Search for dishes"
                          className="w-100 py-2"
                        />
                        <button
                          type="submit"
                          className="bg-transparent"
                          onClick={this.getFilteredItems}
                        >
                          <h5 className="m-0">
                            <i className="fa fa-search"></i>
                          </h5>
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="our-menu__list d-none d-md-block">
                    {this.renderDesktopItemLists()}
                  </div>
                  <div className="our-menu__accrodian-mobile d-block d-md-none">
                    <div className="accordion" id="menu">
                      {this.renderMobileItemLists()}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 d-none d-xl-block">
                <div className="our-menu__cart-mobile">
                  {/* <div className="our-menu__floating-title d-flex align-items-center justify-content-between">
                    <span>Cart (12)</span>
                    <button type="button" className="our-menu__cart-mobile-btn">
                      View Cart{" "}
                      <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                    </button>
                  </div> */}
                  <div className="our-menu__cart">
                    <h4 className="our-menu__cart-title ffr py-2 mb-4">Cart</h4>
                    {this.props.cartItems.length > 0 ? (
                      <div>
                        <table className="table table-borderless">
                          <tbody>
                            {this.renderCardDetail()}
                            <tr>
                              <td className="pl-0 pt-4">
                                <span className="fw500">Subtotal</span>
                                <p className="grey-txt mt-2 fs14">
                                  Extra charges may apply
                                </p>
                              </td>
                              <td className="text-right pl-0 pt-4">
                                <span className="fw500">
                                  $
                                  {parseFloat(
                                    this.props.cartItems.reduce(function (
                                      prev,
                                      current
                                    ) {
                                      return (
                                        prev + +parseFloat(current.sub_total)
                                      );
                                    },
                                    0)
                                  ).toFixed(2)}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <button
                          to="/checkout"
                          type="button"
                          className="our-menu__cart-checkout p-3 fw500 white-txt text-uppercase w-100"
                          onClick={this.checkOutEventHandler}
                        >
                          checkout
                        </button>
                      </div>
                    ) : (
                      <div className="our-menu__cart-empty">
                        <img
                          src="./images/empty-cart.png"
                          alt="empty-cart"
                          className="img-fluid"
                        />
                        <p className="fs16 fw500">
                          Good food is always cooking!
                          <br />
                          Go ahead, order some yummy items from the menu.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    items: state.cartDetail.menuLists,
    isSignedIn: state.auth.isSignedIn,
    cartItems: state.cartDetail.cartItems,
    settings: state.settings,
  };
};

export default connect(mapStateToProps, {
  fetchMenu,
  addToCart,
  fetchCart,
})(OrderNow);
