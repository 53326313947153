import React from "react";
import { NavLink } from "react-router-dom";

const OrderSidebar = () => {
  return (
    <ul className="m-0 p-0">
      <li className="d-block">
        <NavLink
          to="/profile"
          className={({ isActive }) =>
            isActive ? "text-left p-2 w-100 active" : "text-left p-2 w-100"
          }
        >
          <i className="fa fa-user" aria-hidden="true"></i>
          &nbsp;Profile
        </NavLink>

        <NavLink
          to="/order-list"
          type="button"
          className={({ isActive }) =>
            isActive ? "text-left p-2 w-100 active" : "text-left p-2 w-100"
          }
        >
          <i className="fa fa-sticky-note" aria-hidden="true"></i>
          &nbsp;Order History
        </NavLink>

        <NavLink
          to="/change-password"
          type="button"
          className={({ isActive }) =>
            isActive ? "text-left p-2 w-100 active" : "text-left p-2 w-100"
          }
        >
          <i className="fa fa-lock" aria-hidden="true"></i>
          &nbsp;Change Password
        </NavLink>
      </li>
      {/*  <li className="d-block">
        <button type="button" className="text-left p-2 w-100">
          <i className="fa fa-user" aria-hidden="true"></i>
          &nbsp;Profile
        </button>
      </li>
     
      <li className="d-block">
        <button type="button" className="text-left p-2 w-100">
          <i className="fa fa-bell" aria-hidden="true"></i>
          &nbsp;Notification
        </button>
      </li>
      <li className="d-block">
        <button type="button" className="text-left p-2 w-100">
          <i className="fa fa-star" aria-hidden="true"></i>
          &nbsp;Review
        </button>
      </li> */}
    </ul>
  );
};

export default OrderSidebar;
