import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducers from "./authReducers";
import cartReducers from "./cartReducers";
import sidebarReducers from "./sidebarReducers";
import orderListsReducers from "./orderListsReducers";
import loaderReducers from "./loaderReducers";
import siteSettingReducers from "./siteSettingReducers";
import profileReducers from "./profileReducers";

export default combineReducers({
  cartDetail: cartReducers,
  showLoader: loaderReducers,
  auth: authReducers,
  sidebar: sidebarReducers,
  orderLists: orderListsReducers,
  settings: siteSettingReducers,
  profile: profileReducers,
  form: formReducer,
});
