import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ReCAPTCHA from "react-google-recaptcha";
import { contactForm } from "../actions";

class ContactUs extends React.Component {
  captcha = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderError({ error, touched }) {
    if (touched && error) {
      return <div className="ui error message text-danger">{error}</div>;
    }
  }

  renderInput = ({ input, label, inputId, placeholder, type, meta }) => {
    const className = `form-group ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className={className}>
            {type === "textarea" ? (
              <textarea
                className="frm__fld form-control"
                {...input}
                rows="5"
                id={inputId}
                placeholder={placeholder}
              ></textarea>
            ) : (
              <input
                className="frm__fld form-control"
                type={type}
                {...input}
                title={label}
                id={inputId}
                autoComplete="off"
                placeholder={placeholder}
              />
            )}

            {this.renderError(meta)}
          </div>
        </div>
      </div>
    );
  };

  renderRecaptchaField = (field) => {
    const {
      meta: { touched, error },
    } = field;
    return (
      <div style={{ marginTop: "20px" }}>
        <ReCAPTCHA
          sitekey="6LdhMqAdAAAAAH43xQyKICMAYZ3RH-hrg_giFgCE"
          onChange={field.input.onChange}
          ref={(el) => {
            this.captcha = el;
          }}
        />
        <div>
          <p className="text-danger">{touched ? error : ""}</p>
        </div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.contactForm(formValues);
    this.captcha.reset();
  };

  render() {
    return (
      <div>
        <div
          className="internal__banner"
          style={{ backgroundImage: "url('images/about-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="content__item mt-5">
                  <h1 className="text-uppercase text-center">Contact us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="section contact__details">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className="contact__detail__item">
                  <div className="icn">
                    <div className="contact-icon contact-icon-1">
                      <img
                        src="images/contact-1.png"
                        className="img-fluid"
                        alt="C 1"
                      />
                    </div>
                  </div>
                  <div className="content__item">
                    <div className="title brown-txt fw600 ffr lett05 text-uppercase mb-2">
                      Phone Us 24/7
                    </div>
                    <div className="val grey-txt fw500 ffr lett05">
                      <a
                        href={`tel:+${this.props.settings.storeDetails.primary_phone_number.replaceAll(
                          "-",
                          ""
                        )}`}
                      >
                        {this.props.settings.storeDetails.primary_phone_number}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className="contact__detail__item">
                  <div className="icn">
                    <div className="contact-icon contact-icon-2">
                      <img
                        src="images/contact-2.png"
                        className="img-fluid"
                        alt="C 2"
                      />
                    </div>
                  </div>
                  <div className="content__item">
                    <div className="title brown-txt fw600 ffr lett05 text-uppercase mb-2">
                      Get Direction
                    </div>
                    <div className="val grey-txt fw500 ffr lett05">
                      {this.props.settings.storeDetails.address_1
                        ? this.props.settings.storeDetails.address_1 + ","
                        : ""}
                      {this.props.settings.storeDetails.address_2
                        ? "<br/>" +
                          this.props.settings.storeDetails.address_2 +
                          ","
                        : ""}
                      <br />
                      {this.props.settings.storeDetails.city
                        ? this.props.settings.storeDetails.city + ","
                        : ""}
                      {this.props.settings.storeDetails.state
                        ? this.props.settings.storeDetails.state + ","
                        : ""}
                      {this.props.settings.storeDetails.zip}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className="contact__detail__item">
                  <div className="icn">
                    <div className="contact-icon contact-icon-3">
                      <img
                        src="images/contact-3.png"
                        className="img-fluid"
                        alt="C 3"
                      />
                    </div>
                  </div>
                  <div className="content__item">
                    <div className="title brown-txt fw600 ffr lett05 text-uppercase mb-2">
                      Opening Hours
                    </div>
                    <div className="val grey-txt fw500 ffr lett05">
                      {this.props.settings.storeDetails.store_timings}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section py-150 contact__us"
          style={{ backgroundImage: "url('images/contact-form-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="image">
                  <img
                    src="images/about-story-img.png"
                    className="img-fluid"
                    alt="Storey Img"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="contact__form">
                  <form
                    id="contact-form"
                    onSubmit={this.props.handleSubmit(this.onSubmit)}
                  >
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="form__title mb-5 ffl brown-txt text-uppercase lett1">
                          Write <span className="orange-txt">To Us</span>
                        </div>
                      </div>
                    </div>

                    <Field
                      name="name"
                      type="text"
                      placeholder="Your Name"
                      component={this.renderInput}
                    />

                    <Field
                      name="email"
                      type="text"
                      placeholder="Email"
                      component={this.renderInput}
                    />

                    <Field
                      name="message"
                      type="textarea"
                      placeholder="Write your text"
                      component={this.renderInput}
                    />

                    <Field
                      name="recaptcha"
                      component={this.renderRecaptchaField}
                    />

                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="form-group">
                          <input
                            type="submit"
                            value="SEND"
                            className="frm__sbt orange__btn form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section map__iframe p-0">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28764.109232214316!2d-80.31981!3d25.687412!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1653ffa87499580b!2sPita%20Fresh!5e0!3m2!1sen!2sin!4v1639585350952!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0, marginBottom: "-7px" }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </section>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  const { recaptcha } = formValues;

  if (!recaptcha) {
    errors.recaptcha = "reCAPTCHA required.";
  }

  if (!formValues.message) {
    errors.message = "Enter Message";
  }

  if (!formValues.email) {
    errors.email = "Enter Email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.email = "Invalid email address";
  }

  if (!formValues.name) {
    errors.name = "Enter Name";
  }
  return errors;
};

const formWrapper = reduxForm({
  validate,
  form: "contactForm",
})(ContactUs);

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps, { contactForm })(formWrapper);
