import * as actionTypes from "../actions/types";

const INITIAL_STATE = {
  activeOrders: [],
  pastOrders: [],
  showLoadMore: false,
};

const orderListsReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERS:
      const showLoadMore = action.payload.pastOrder.length > 0 ? true : false;
      state.pastOrders.push(...action.payload.pastOrder);
      return {
        ...state,
        activeOrders: action.payload.activeOrder,
        pastOrders: state.pastOrders,
        showLoadMore: showLoadMore,
      };
    default:
      return state;
  }
};

export default orderListsReducers;
