import React from "react";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";

class Footer extends React.Component {
  render() {
    if (
      this.props.auth.show_verification === true ||
      this.props.auth.show_verification === "true"
    ) {
      return <Navigate to="email-verification" />;
    }
    return (
      <footer id="footer" className="purple-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <div className="foot__item">
                <div className="foot__logo mb-4 pb-2">
                  <img src="images/logo.png" className="img-fluid" alt="Logo" />
                </div>
                <h6 className="orange-txt text-uppercase fw600 ffr mb-3">
                  ABOUT pita Fresh
                </h6>
                <div className="desc fs14">
                  Each visit is an unparalleled experience, As well known and we
                  are very busy all day advising you. advise you to call us
                  before arriving.
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div className="foot__item">
                <h6 className="foot__title white-txt text-uppercase ffr">
                  Links
                </h6>
                <div className="foot__content">
                  <ul className="foot__menu">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About</Link>
                    </li>
                    <li>
                      <Link to="/our-menu">Menu</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
              <div className="foot__item">
                <h6 className="foot__title white-txt text-uppercase ffr">
                  Contact Us
                </h6>
                <div className="foot__content">
                  <div className="add__item__main">
                    <div className="add__item">
                      <span>ADDRESS:</span>
                      <a
                        href="https://www.google.com/maps?ll=25.687412,-80.31981&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=1608910590877194251"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {this.props.settings.storeDetails.address_1
                          ? this.props.settings.storeDetails.address_1 + ","
                          : ""}
                        {this.props.settings.storeDetails.address_2
                          ? this.props.settings.storeDetails.address_2 + ","
                          : ""}
                        {this.props.settings.storeDetails.city
                          ? this.props.settings.storeDetails.city + ","
                          : ""}
                        {this.props.settings.storeDetails.state
                          ? this.props.settings.storeDetails.state + ","
                          : ""}
                        {this.props.settings.storeDetails.zip}
                      </a>
                    </div>
                    <div className="add__item">
                      <span>MAIL:</span>
                      <a
                        href={`mailto:${this.props.settings.storeDetails.email}`}
                        className="white-txt"
                        title="Mail"
                      >
                        {this.props.settings.storeDetails.email}
                      </a>
                    </div>
                    <div className="add__item">
                      <span>PHONE:</span>
                      <a
                        href={`tel:+${this.props.settings.storeDetails.primary_phone_number.replaceAll(
                          "-",
                          ""
                        )}`}
                        className="white-txt"
                        title="Call"
                      >
                        {this.props.settings.storeDetails.primary_phone_number}
                      </a>
                    </div>
                    {this.props.settings.storeDetails.fax ? (
                      <div className="add__item">
                        <span>FAX ID:</span>
                        {this.props.settings.storeDetails.fax}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
              <div className="foot__item">
                <div className="foot__contact__item">
                  <div className="icn">
                    <img
                      src="images/time-icn.png"
                      className="img-fluid"
                      alt="Time Icon"
                    />
                  </div>
                  <div className="content__item">
                    <div className="title orange-txt fw600 ffr lett1  text-uppercase mb-2">
                      Opening Hours
                    </div>
                    <div className="val fw500 fs14 ffr lett05">
                      {/* Monday - Saturday (11:00 - 10:45) Sunday - Closed */}
                      {this.props.settings.storeDetails.store_timings}
                    </div>
                  </div>
                </div>
              </div>

              <div className="foot__social-share">
                {this.props.settings.storeDetails.facebook !== "" &&
                this.props.settings.storeDetails.facebook !== null ? (
                  <a
                    href={this.props.settings.storeDetails.facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                ) : null}

                {this.props.settings.storeDetails.instagram !== "" &&
                this.props.settings.storeDetails.instagram !== null ? (
                  <a href={this.props.settings.storeDetails.instagram}>
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                ) : null}

                {this.props.settings.storeDetails.twitter !== "" &&
                this.props.settings.storeDetails.twitter !== null ? (
                  <a href={this.props.settings.storeDetails.twitter}>
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                ) : null}

                {this.props.settings.storeDetails.google !== "" &&
                this.props.settings.storeDetails.google !== null ? (
                  <a href={this.props.settings.storeDetails.google}>
                    <i className="fa fa-google" aria-hidden="true"></i>
                  </a>
                ) : null}

                {this.props.settings.storeDetails.you_tube !== "" &&
                this.props.settings.storeDetails.you_tube !== null ? (
                  <a href={this.props.settings.storeDetails.you_tube}>
                    <i className="fa fa-youtube-play" aria-hidden="true"></i>
                  </a>
                ) : null}
                {this.props.settings.storeDetails.yelp !== "" &&
                this.props.settings.storeDetails.yelp !== null ? (
                  <a href={this.props.settings.storeDetails.yelp}>
                    <i className="fa fa-yelp" aria-hidden="true"></i>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="footer-copyright position-relative">
                <span className="fs14">
                  © {new Date().getFullYear()} Pita Fresh. All Rights Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(Footer);
