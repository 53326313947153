import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class AboutUs extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    window.jQuery(".owl-testi").owlCarousel({
      loop: false,
      margin: 0,
      dots: false,
      nav: true,
      responsiveClass: true,
      items: 1,
      navText: [
        "<img src='./images/arrow-previous.png' alt='arrow-previous'>",
        "<img src='./images/arrow-next.png' alt='arrow-next'>",
      ],
    });
  }

  render() {
    return (
      <div>
        <div
          className="internal__banner"
          style={{ backgroundImage: "url('images/about-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="content__item mt-5">
                  <h1 className="text-uppercase text-center">About us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="about__services">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div
                  className="about__service__item text-center green-bg"
                  style={{ backgroundImage: "url('images/about-1-bg.png')" }}
                >
                  <div className="icon mb-4">
                    <img
                      src="images/about-icn-1.png"
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  <h4 className="white-txt lett2 text-uppercase">
                    {this.props.settings.storeDetails.ingredients}+
                  </h4>
                  <h6 className="white-txt mt-2 lett1 ffr">Ingredients</h6>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div
                  className="about__service__item text-center orange-bg"
                  style={{ backgroundImage: "url('images/about-2-bg.png')" }}
                >
                  <div className="icon mb-4">
                    <img
                      src="images/about-icn-2.png"
                      className="img-fluid"
                      alt="Icon 2"
                    />
                  </div>
                  <h4 className="white-txt lett2 text-uppercase">
                    {this.props.settings.storeDetails.food_items}+
                  </h4>
                  <h6 className="white-txt mt-2 lett1 ffr">Food Items</h6>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div
                  className="about__service__item text-center green-bg"
                  style={{ backgroundImage: "url('images/about-1-bg.png')" }}
                >
                  <div className="icon mb-4">
                    <img
                      src="images/about-icn-3.png"
                      className="img-fluid"
                      alt="Icon 3"
                    />
                  </div>
                  <h4 className="white-txt lett2 text-uppercase">
                    {this.props.settings.storeDetails.daily_food_lovers}+
                  </h4>
                  <h6 className="white-txt mt-2 lett1 ffr">
                    Daily Food Lovers
                  </h6>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div
                  className="about__service__item text-center orange-bg"
                  style={{ backgroundImage: "url('images/about-2-bg.png')" }}
                >
                  <div className="icon mb-4">
                    <img
                      src="images/about-icn-4.png"
                      className="img-fluid"
                      alt="Icon 4"
                    />
                  </div>
                  <h4 className="white-txt lett2 text-uppercase">
                    {this.props.settings.storeDetails.year_of_experience}+
                  </h4>
                  <h6 className="white-txt mt-2 lett1 ffr">
                    Year of Experience
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section about__story">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="image">
                  <img
                    src="images/about-story-img.png"
                    className="img-fluid"
                    alt="Story 1"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="content__item">
                  <h3 className="ffl text-uppercase lett05 mb-4">
                    The Story About <br />
                    Pita Fresh, Only For <br />
                    <span className="orange-txt">Hungry People.</span>
                  </h3>
                  <div className="desc">
                    Engaging to the eye this multi-cuisine restaurant does
                    stimulate the senses fully. Pitafresh provides delicious
                    flavors, Veg & Non Veg Food, with an exotic range of
                    platters and drinks all under one roof with a soothing
                    environment. You will find the best place to dine with your
                    friends, family or with your loved one.
                  </div>
                  <div className="tabs__main mt-5">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#history"
                        >
                          History
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#our-journey"
                        >
                          Our Journey
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#experience"
                        >
                          Experience
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div className="tab-pane active" id="history">
                        <div className="tab__content">
                          <div className="desc mb-5">
                            Born in 2006 in the Miami, and celebrating 15 years
                            of operations, Pitafresh has influenced the
                            world-view of an entire generation, to stir a social
                            revolution.
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="our-journey">
                        <div className="tab__content">
                          <div className="desc mb-5">
                            Pitafresh restaurant in the city located on FL 33156
                            Mailbon Star. Despite not knowing a ton about the
                            restaurant industry, his owner decided to take a
                            risk and decades later, that risk proved to be a
                            successful one. Through hard work, determination,
                            and risk-taking, Pitafresh has turned itself into
                            one of the premier restaurateurs in the city.
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="experience">
                        <div className="tab__content">
                          <div className="desc mb-5">
                            While it might not look like much from the outside,
                            Pitafresh has been serving up some of Miami’s best
                            Chicken Kebab for more than decades. Make sure not
                            to miss out on the chicken shawarma and falafel
                            salad as well.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section fresh__taste black-bg"
          style={{ backgroundImage: "url('images/fresh-taste-img.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="content__item">
                  <h2 className="text-uppercase ffl white-txt">
                    Fresh Taste At A <br />
                    Great Price, Only For <br />
                    <span className="orange-txt">Delicious Food Lover.</span>
                  </h2>
                  <Link
                    to="/order-now"
                    className="green__btn mt-5"
                    title="Order Now"
                  >
                    Order Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-150 who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="image">
                  <img
                    src="images/who-we-are-img.png"
                    className="img-fluid"
                    alt="Who we are"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className="content__item">
                  <h5 className="orange-txt ffs">Who We Are?</h5>
                  <h3 className="brown-txt lett1 ffl my-4">
                    BEST FOOD IDEAS & <br />
                    TRADITIONS FROM THE <br />
                    MIAMI
                  </h3>
                  <div className="desc pb-3">
                    Good choice. Pita Fresh is not just one of the most popular
                    places for delivery in Miami, it's one of the 3
                    Mediterranean delivery spots with the most orders. Wish you
                    could ask someone what's best here? Users love the chicken
                    kebab, which is one of the most popular items on the menu at
                    this evening
                  </div>
                  <div className="who__we__item__main mt-4">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="who__we__item">
                          <div className="icn light-orange-bg">
                            <img
                              src="images/w-icn-1.png"
                              className="img-fluid"
                              alt="W Icon"
                            />
                          </div>
                          <h6 className="brown-txt ffr fw600">Fresh Food</h6>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="who__we__item">
                          <div className="icn light-orange-bg">
                            <img
                              src="images/w-icn-2.png"
                              className="img-fluid"
                              alt="W Icon 3"
                            />
                          </div>
                          <h6 className="brown-txt ffr fw600">
                            Quality Maintain
                          </h6>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="who__we__item">
                          <div className="icn light-orange-bg">
                            <img
                              src="images/w-icn-3.png"
                              className="img-fluid"
                              alt="W Icon 2"
                            />
                          </div>
                          <h6 className="brown-txt ffr fw600">
                            100% Halal Meat
                          </h6>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="who__we__item">
                          <div className="icn light-orange-bg">
                            <img
                              src="images/w-icn-4.png"
                              className="img-fluid"
                              alt="W Icon 3"
                            />
                          </div>
                          <h6 className="brown-txt ffr fw600">
                            Variety of Choices
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section
          className="section testimonial"
          style={{ backgroundImage: "url('images/testimonial-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="owl-carousel owl-testi owl-theme">
                  <div className="item text-center">
                    <div className="content__item">
                      <div className="comment brown-txt ffr mb-5">
                        “We have no regrets! I don't know what else to say. It
                        really <br />
                        saves me time and effort. Food is exactly what our
                        business <br />
                        has been lacking”
                      </div>
                      <h4 className="orange-txt ffs">Davis J. Rulia</h4>
                      <div className="designation grey-txt mt-3">
                        Food Bloger
                      </div>
                    </div>
                  </div>
                  <div className="item text-center">
                    <div className="content__item">
                      <div className="comment brown-txt ffr mb-5">
                        “We have no regrets! I don't know what else to say. It
                        really <br />
                        saves me time and effort. Food is exactly what our
                        business <br />
                        has been lacking”
                      </div>
                      <h4 className="orange-txt ffs">Davis J. Rulia</h4>
                      <div className="designation grey-txt mt-3">
                        Food Bloger
                      </div>
                    </div>
                  </div>
                  <div className="item text-center">
                    <div className="content__item">
                      <div className="comment brown-txt ffr mb-5">
                        “We have no regrets! I don't know what else to say. It
                        really <br />
                        saves me time and effort. Food is exactly what our
                        business <br />
                        has been lacking”
                      </div>
                      <h4 className="orange-txt ffs">Davis J. Rulia</h4>
                      <div className="designation grey-txt mt-3">
                        Food Bloger
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(AboutUs);
