import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { signIn } from "../../actions";

class LoginForm extends React.Component {
  renderError({ error, touched }) {
    if (touched && error) {
      return <div className="ui error message text-danger">{error}</div>;
    }
  }

  renderInput = ({ input, label, inputId, placeholder, type, meta }) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""}`;
    return (
      <div className={className}>
        <label htmlFor={inputId}>{label}</label>
        <input
          type={type}
          {...input}
          title={label}
          id={inputId}
          autoComplete="off"
          placeholder={placeholder}
        />
        {this.renderError(meta)}
      </div>
    );
  };

  onSubmit = (formValues) => {
    this.props.signIn(formValues);
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        {/* this.props.auth.error ? (
          <p className="red error">{this.props.auth.error}</p>
        ) : null */}
        <Field
          name="email"
          type="text"
          placeholder="Email"
          component={this.renderInput}
        />
        <Field
          name="password"
          type="password"
          placeholder="Password"
          component={this.renderInput}
        />
        <button type="submit" className="orange__btn text-uppercase">
          login
        </button>
        <span>
          By clicking on Login, I accept the Terms & Conditions & Privacy Policy
        </span>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.email) {
    errors.email = "Enter Email";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.email = "Invalid email address";
  }

  if (!formValues.password) {
    errors.password = "Enter password";
  } else if (formValues.password.length < 5) {
    errors.password = "Must be 5 characters or more";
  }
  return errors;
};

const formWrapper = reduxForm({
  validate,
  form: "loginForm",
})(LoginForm);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { signIn })(formWrapper);
