import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signOut, sidebarOpen } from "../actions";
import { toast } from "react-toastify";

const Header = (props) => {
  let navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(false);

  const dropDownClassName =
    showDropdown === true
      ? "header__dropdown-box show"
      : "header__dropdown-box";

  const fevIconClassName =
    showDropdown === true ? "fa fa-chevron-up ml-1" : "fa fa-chevron-down ml-1";

  useEffect(
    (props) => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 150);
      });

      setShowDropdown(false);
      setMobileDropdown(false);
    },
    [navigate]
  );

  const onDropdownMenuClick = () => {
    setShowDropdown(!showDropdown);
  };

  const logoutClick = () => {
    props.signOut();
    setTimeout(function () {
      navigate("/");
    }, 1000);
  };

  const checkOutEventHandler = () => {
    /* if (props.auth.isSignedIn === false) {
      toast.error("Please login before chekout");
      return;
    } */
    navigate("/checkout");
  };

  return (
    <header
      id="header"
      className={scroll ? "animated fadeInDown fixed-top" : ""}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="main-menu">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                  <img src="images/logo.png" alt="logo" className="img-fluid" />
                </Link>
                <div className="navbar-mobile-buttons">
                  <Link
                    to="/order-now"
                    className="mr-3 d-xl-none"
                    title="Order Now"
                  >
                    <img
                      src="./images/order-icon.png"
                      alt="order-icon"
                      className="img-fluid"
                    />
                  </Link>
                  {!props.auth.isSignedIn ? (
                    <button
                      className="mr-3 d-xl-none href-no-bg"
                      onClick={props.sidebarOpen}
                      title="Login Icon"
                    >
                      <img src="./images/user.png" alt="user" />
                    </button>
                  ) : null}

                  {props.cartCount > 0 ? (
                    <button
                      className="href-no-bg d-xl-none"
                      title="Cart"
                      onClick={checkOutEventHandler}
                    >
                      <img
                        src="./images/shopping-basket.png"
                        alt="shopping-basket"
                      />

                      <span className="mobile shopping-cart-items-count">
                        {props.cartCount}
                      </span>
                    </button>
                  ) : null}

                  <button
                    className={`navbar-toggler ${
                      mobileDropdown === false ? "collapsed" : ""
                    }`}
                    onClick={() => setMobileDropdown(!mobileDropdown)}
                    type="button"
                    data-toggle="collapse"
                    aria-expanded={mobileDropdown === false ? "false" : "true"}
                  >
                    <span className="navbar-toggler-icon">
                      <i
                        className="fa fa-bars orange-txt"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </button>
                </div>
                <div
                  className={`collapse navbar-collapse ${
                    mobileDropdown === true ? "show" : ""
                  }`}
                  id="navbarNavDropdown"
                >
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="nav-item navbar__hasChildren">
                      <Link className="nav-link" to="/about-us">
                        About
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/our-menu">
                        Our Menu
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact-us">
                        Contact
                      </Link>
                    </li>
                    <li className="nav-item d-xl-none">
                      <Link className="nav-link" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    {props.auth.isSignedIn ? (
                      <>
                        <li className="nav-item d-xl-none">
                          <Link className="nav-link" to="/profile">
                            Profile
                          </Link>
                        </li>
                        <li className="nav-item d-xl-none">
                          <Link className="nav-link" to="/order-list">
                            Order History
                          </Link>
                        </li>
                        <li className="nav-item d-xl-none">
                          <button
                            className="nav-link href-no-bg nav-link-btn"
                            onClick={logoutClick}
                          >
                            Logout
                          </button>
                        </li>
                      </>
                    ) : null}
                  </ul>

                  <div className="head__btns d-flex align-items-center">
                    <Link
                      to="/order-now"
                      className="orange__btn d-none d-xl-block"
                      title="Order Now"
                    >
                      <span>Order Now</span>
                    </Link>

                    {props.cartCount > 0 ? (
                      <button
                        className="position-relative d-lg-inline-flex d-none ml-3 href-no-bg"
                        title="Cart"
                        onClick={checkOutEventHandler}
                      >
                        <span className="round__icn">
                          <img
                            src="./images/shopping-basket.png"
                            alt="shopping-basket"
                          />
                        </span>

                        <span className="shopping-cart-items-count">
                          {props.cartCount}
                        </span>
                      </button>
                    ) : null}

                    {props.auth.isSignedIn ? (
                      <div className="header__dropdown">
                        <button
                          onClick={onDropdownMenuClick}
                          className="header__dropdown-link ml-3 href-no-bg dropdown2"
                          title="Profile"
                        >
                          <span className="round__icn">
                            <img src="./images/user.png" alt="user" />
                          </span>
                          <span className="ml-3">
                            {props.auth.name}
                            <i className={fevIconClassName}></i>
                          </span>
                        </button>
                        <div className={dropDownClassName}>
                          <ul>
                            <li>
                              <Link to="/profile">Profile</Link>
                            </li>
                            <li>
                              <Link to="/order-list">Order History</Link>
                            </li>
                            <li>
                              <button
                                style={{ fontSize: "15px", fontWeight: 500 }}
                                className="href-no-bg"
                                onClick={logoutClick}
                              >
                                Logout
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : null}

                    {props.auth.isSignedIn ? (
                      ""
                    ) : (
                      <button
                        className="d-lg-inline-flex d-none ml-3 href-no-bg"
                        onClick={props.sidebarOpen}
                        title="Login Icon"
                      >
                        <span className="round__icn">
                          <img src="./images/user.png" alt="user" />
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    cartCount: state.cartDetail.cartItems.length,
  };
};

export default connect(mapStateToProps, { signOut, sidebarOpen })(Header);
