import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { fetchMenu, addToCart, fetchCart } from "../actions";

class OurMenu extends React.Component {
  state = {
    isRedirect: false,
    showVarient: false,
    activeItem: null,
    searchItem: "",
    activeCategory: "Picked for you",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchMenu();
    this.props.fetchCart();

    if (this.props.items.length > 0) {
      const firstItem = this.props.items[0];
      this.setState({ activeCategory: firstItem.name });
    }
  }

  getFilteredItems = (e) => {
    e.preventDefault();
    const searchItem = this.state.searchItem;
    this.props.fetchMenu(searchItem);
  };

  addItemToCart = (item) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      if (
        item.item_variant_category_map &&
        item.item_variant_category_map.length > 0
      ) {
        this.setState({ showVarient: true, activeItem: item });
      } else {
        this.props.addToCart(item.id, 1);
      }
    } else {
      console.log("Please Login to add item in cart");
    }
  };

  addItemWithVarient = (item) => {
    let keys = [];
    Object.entries(item.item_variant_category_map).forEach(([key, value]) => {
      if (value.is_multiple) {
        if (
          window.jQuery(
            'input:checkbox[name="item_name_' + value.id + '[]"]:checked'
          ).length > 0
        ) {
          window
            .jQuery(
              'input:checkbox[name="item_name_' + value.id + '[]"]:checked'
            )
            .each(function () {
              keys.push(window.jQuery(this).val());
            });
        }
      } else {
        if (
          window
            .jQuery('input[name="item_name_' + value.id + '"]:checked')
            .val() !== undefined &&
          window
            .jQuery('input[name="item_name_' + value.id + '"]:checked')
            .val() !== null
        ) {
          keys.push(
            document.querySelector(
              'input[name="item_name_' + value.id + '"]:checked'
            ).value
          );
        }
      }
    });

    this.props.addToCart(item.id, 1, keys);
    this.setState({ showVarient: false, activeItem: null });

    /* item.item_variant_category_map.each(function () {
      debugger;
    }); */
    /* window
      .jQuery('input:checkbox[name="item_name_77[]"]:checked')
      .each(function () {
        yourArray.push(window.jQuery(this).val());
      }); */
  };

  increaseItemInCart = (item) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const qty = (item.qty += 1);
      this.props.addToCart(item.item_id, qty, [], item.id);
    } else {
      console.log("Please Login to add item in cart");
    }
  };

  decreaseItemInCart = (item) => {
    const getToken = localStorage.getItem("token");
    if (getToken && getToken !== "undefined" && getToken !== "null") {
      const qty = (item.qty -= 1);
      this.props.addToCart(item.item_id, qty, [], item.id);
    } else {
      console.log("Please Login to add item in cart");
    }
  };

  renderDesktopItemLists = () => {
    return this.props.items.map((item, index) => {
      return (
        <div key={index} id={item.name.split(/\s/).join("")}>
          <div className="our-menu__list-title">
            <h4 className="orange-txt fw700 mb-2 ffr">{item.name}</h4>
            <span className="text-uppercase grey-txt">
              {item.items.length} items
            </span>
          </div>

          {item.items.map((singleItem, index) => {
            return (
              <div
                className="our-menu__list-item py-4 d-flex align-items-center justify-content-between added-in-cart"
                key={index}
              >
                <div className="our-menu__list-item-content">
                  {singleItem.is_bestseller ? (
                    <div className="mb-2 orange-txt bestseller-txt">
                      <i className="fa fa-star"></i> Bestseller
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <img
                      src={singleItem.food_category_icon}
                      alt={singleItem.name}
                      className="img-fluid"
                      style={{ width: "16px", height: "16px" }}
                    />
                    <h6 className="fw500 ffr ml-2 mr-2">{singleItem.name}</h6>
                  </div>

                  <span className="fs14">${singleItem.price}</span>
                  <p className="grey-txt fw400 mt-3 mb-0 fs14">
                    {singleItem.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  renderMobileItemLists = () => {
    return this.props.items.map((item, index) => {
      return (
        <div className="card" key={index}>
          <div className="card-header" id={`menuhead${index}`}>
            <button
              className="btn-header-link active"
              data-toggle="collapse"
              data-target={`#menu${index}`}
              aria-controls={`menu${index}`}
            >
              {item.name} ({item.items.length})
            </button>
          </div>

          <div
            id={`menu${index}`}
            className="collapse show"
            aria-labelledby={`menuhead${index}`}
            data-parent="#menu"
          >
            <div className="card-body p-0">
              {item.items.map((singleItem, index) => {
                return (
                  <div
                    className="our-menu__list-item py-4 d-flex align-items-center justify-content-between"
                    key={index}
                  >
                    <div className="our-menu__list-item-content">
                      <div className="item-info">
                        {singleItem.is_bestseller ? (
                          <div className="mb-2 orange-txt bestseller-txt">
                            <i className="fa fa-star"></i> Bestseller
                          </div>
                        ) : null}
                        <div className="d-flex">
                          <img
                            src={singleItem.food_category_icon}
                            alt={singleItem.name}
                            className="img-fluid"
                            style={{ width: "16px", height: "16px" }}
                          />
                          <h6 className="fw500 ffr ml-2 mr-2">
                            {singleItem.name}
                          </h6>
                        </div>
                        <span className="fs14">${singleItem.price}</span>
                        <p className="grey-txt fw400 mt-3 mb-0 fs14">
                          {singleItem.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    });
  };

  changeActiveCategory = (item) => {
    this.setState({ activeCategory: item.name });

    window.jQuery("html, body").animate(
      {
        scrollTop:
          window.jQuery("#" + item.name.split(/\s/).join("") + "").offset()
            .top -
          (window.jQuery("#header").outerHeight() + 20),
      },
      100
    );
  };

  renderCategoryItems = () => {
    return (
      <div className="our-menu__filter d-none d-md-block">
        <ul className="m-0 p-0">
          {this.props.items.map((item, index) => {
            return (
              <li
                className="d-block"
                key={index}
                onClick={() => {
                  this.changeActiveCategory(item);
                }}
              >
                <button
                  type="button"
                  className={`text-left p-2 w-100 ${
                    item.name === this.state.activeCategory ? "active" : ""
                  }`}
                >
                  {item.name}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  renderCardDetail = () => {
    return this.props.cartItems.map((item, index) => {
      return (
        <tr className="grey-txt" key={index}>
          <td className="py-1 pl-0 fs14">{item.name}</td>
          <td className="py-1 pl-0">
            <div className="our-menu__list-item-button d-flex align-items-center justify-content-between">
              <button
                style={{ cursor: "pointer" }}
                onClick={() => this.decreaseItemInCart(item)}
                type="button"
                className="quantity-minus bg-transparent fs16"
              >
                <i className="fa fa-minus"></i>
              </button>
              <span className="text-center fw500 fs16 price-box">
                {item.qty}
              </span>
              <button
                style={{ cursor: "pointer" }}
                onClick={() => this.increaseItemInCart(item)}
                type="button"
                className="quantity-plus bg-transparent fs16"
              >
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </td>
          <td className="py-1 pl-0 fs14 text-right">${item.price}</td>
        </tr>
      );
    });
  };

  render() {
    if (this.state.isRedirect) {
      return <Navigate to="/order-list" />;
    }
    return (
      <div>
        <div
          className="internal__banner"
          style={{ backgroundImage: "url('images/about-bg.png')" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="content__item mt-5">
                  <h1 className="text-uppercase text-center">our menu</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="our-menu py-150">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                {/* <div className="d-block d-md-none mb-5">
                  <form onSubmit={this.getFilteredItems}>
                    <div className="our-menu__search d-flex align-items-center">
                      <input
                        type="text"
                        name=""
                        id="searchDishes"
                        placeholder="Search for dishes"
                        className="w-100 py-2"
                        value={this.state.searchItem}
                        onChange={(e) =>
                          this.setState({ searchItem: e.target.value })
                        }
                      />
                      <button
                        type="submit"
                        className="bg-transparent"
                        onClick={this.getFilteredItems}
                      >
                        <h5 className="m-0">
                          <i className="fa fa-search"></i>
                        </h5>
                      </button>
                    </div>
                  </form>
                </div> */}

                {this.renderCategoryItems()}
              </div>

              <div className="col-12 col-sm-12 col-md-9 col-lg-10 col-xl-10">
                <div className="our-menu__list-container">
                  {/* <div className="d-none d-md-block">
                    <form onSubmit={this.getFilteredItems}>
                      <div className="our-menu__search d-flex align-items-center">
                        <input
                          type="text"
                          value={this.state.searchItem}
                          onChange={(e) =>
                            this.setState({ searchItem: e.target.value })
                          }
                          placeholder="Search for dishes"
                          className="w-100 py-2"
                        />
                        <button
                          type="submit"
                          className="bg-transparent"
                          onClick={this.getFilteredItems}
                        >
                          <h5 className="m-0">
                            <i className="fa fa-search"></i>
                          </h5>
                        </button>
                      </div>
                    </form>
                  </div> */}

                  <div className="our-menu__list d-none d-md-block">
                    {this.renderDesktopItemLists()}
                  </div>
                  <div className="our-menu__accrodian-mobile d-block d-md-none">
                    <div className="accordion" id="menu">
                      {this.renderMobileItemLists()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    items: state.cartDetail.menuLists,
    cartItems: state.cartDetail.cartItems,
  };
};

export default connect(mapStateToProps, {
  fetchMenu,
  addToCart,
  fetchCart,
})(OurMenu);
